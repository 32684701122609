import InputTextField from '@ui/form/inputTextField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: string[]
}

export default function LocationField({ control, defaultValue }: Props): JSX.Element {
	const firstValue = defaultValue != null ? defaultValue[0] : undefined
	const { t } = useTranslation()
	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.locations.title')}
			tooltip={t('tracks:details.formFields.locations.tooltip')}
		>
			<InputTextField control={control} name="locations" defaultValue={firstValue ?? ''} />
		</TableLayoutRow>
	)
}
