import { ErrorHandler } from '#helpers/error.helper'
import { type ApolloError } from '@apollo/client/errors'
import {
	type UpdateUserPositionInput,
	type UserPositionListNodeFragment,
	useUpdateUserPositionMutation,
	UserPositionListNodeFragmentDoc,
	GetUserInterviewListDocument
} from '@gqlTypes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Severities } from '@application/snackbar/Severities'
import { useApolloClient } from '@apollo/client'
import { useLocation } from 'react-router-dom'

interface UsePositionUpdate {
	update: (userPositionInput: UpdateUserPositionInput) => void
}

export const usePositionUpdate = (position: UserPositionListNodeFragment): UsePositionUpdate => {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const [updateUserPosition] = useUpdateUserPositionMutation()
	const client = useApolloClient()
	let url = useLocation().pathname.substring(1)
	if (useLocation().pathname.includes('students')) url = url.split('/')[0]

	const userPositionCache = client.readFragment({
		id: `UserPosition:${position.id}`,
		fragment: UserPositionListNodeFragmentDoc
	})

	const update = (userPositionInput: UpdateUserPositionInput): void => {
		;(async () => {
			await updateUserPosition({
				variables: { userPositionId: position.id, userPositionInput },
				onCompleted,
				onError,
				refetchQueries: [GetUserInterviewListDocument],
				optimisticResponse: {
					updateUserPosition: {
						...userPositionCache,
						...userPositionInput,
						userInfo: {
							...userPositionCache.userInfo,
							...userPositionInput.userInfo
						}
					}
				}
			})
		})().catch(updateUserPosition)
	}

	const onCompleted = (): void => {
		enqueueSnackbar(t(`${url}:update.success`), { variant: Severities.success })
	}
	const onError = (err: ApolloError): void => {
		enqueueSnackbar(t(`${url}:update.error`), { variant: Severities.error })
		ErrorHandler(err)
	}

	return { update }
}
