import { type Dispatch, type ReactElement, type SetStateAction, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { FeedbackMessageSection } from '../sections/FeedbackMessageSection'
import { OrgaSkillSection } from '../sections/OrgaSkillSection'
import { DialogSection } from '@shared/components/modal/dialogSection'
import {
	FeedbackRequestMode,
	type IntegrationCategory,
	type TrackType,
	useSendBrandFeedbackRequestMutation
} from '@gqlTypes'
import { toState } from '../../../../helper/array.helper'
import { Stack, ToggleButton, ToggleButtonGroup, Typography, Box, Tooltip, IconButton, TextField } from '@mui/material'
import { useFilters } from '@shared/hooks/useFilters'
import { searchQuery } from '@features/lists/components/Search'
import { useOrganization } from '@domains/organization/useOrganization'
import { LoadingButton } from '@mui/lab'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { type DateTime } from 'luxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

interface Props {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	trackType: TrackType
	userIntegrationsIds: string[]
	count: number
	integrationCategory: IntegrationCategory
}

export const MassFeedbackModal = ({
	open,
	setOpen,
	trackType,
	userIntegrationsIds,
	count,
	integrationCategory
}: Props): ReactElement => {
	const { t } = useTranslation()
	const { organization, hasOrgaSkills } = useOrganization()
	const [message, setMessage] = useState(
		(organization?.defaultOrgaRequest !== '' ? organization?.defaultOrgaRequest : t('modal:massFeedback.message')) ?? ''
	)
	const [sendFeedbackRequestMutation, { loading: isLoading }] = useSendBrandFeedbackRequestMutation()
	const { filters } = useFilters()
	const [mode, setMode] = useState(FeedbackRequestMode.Auto)
	const [skills, setSkills] = useState(toState(organization?.orgaSkills ?? []))
	const [dueDate, setDueDate] = useState<Date | null>(null)
	const selectedSkills = Object.keys(skills).filter((key) => skills[key])
	const themes = selectedSkills.length

	const sendFeedbackRequest = async (): Promise<void> => {
		const variables = {
			userIntegrationsIds,
			trackType,
			filters,
			skillToEvaluateIds: selectedSkills,
			message,
			mode,
			integrationCategory,
			searchQuery: searchQuery(),
			dueDate
		}
		await sendFeedbackRequestMutation({ variables })
		setOpen(false)
	}

	const handleMode = (_e: unknown, mode: FeedbackRequestMode): void => {
		if (mode !== null) setMode(mode)
	}

	const isSubmitDisable = (mode === FeedbackRequestMode.Custom && message === '') || !hasOrgaSkills

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false)
			}}
		>
			<DialogTitle>
				<Stack direction="row" justifyContent="space-between">
					{t('modal:massFeedback.title', { count })}
					<ToggleButtonGroup
						color="primary"
						value={mode}
						exclusive
						onChange={handleMode}
						aria-label="Mode"
						sx={{ alignSelf: 'center' }}
						size="small"
					>
						<ToggleButton value={FeedbackRequestMode.Auto}>{t('modal:massFeedback.mode.auto.button')}</ToggleButton>
						<ToggleButton value={FeedbackRequestMode.Custom}>{t('modal:massFeedback.mode.custom.button')}</ToggleButton>
					</ToggleButtonGroup>
				</Stack>
			</DialogTitle>
			<Divider />
			<DialogSection>
				<Stack gap={3}>
					{mode === FeedbackRequestMode.Auto ? (
						<Typography>{t('modal:massFeedback.mode.auto.text')}</Typography>
					) : (
						<>
							<FeedbackMessageSection message={message} setMessage={setMessage} />
							<Stack spacing={3}>
								<Box>
									<Typography gutterBottom>
										<b>{t('modal:massFeedback.title_theme', { count: themes })}</b>
									</Typography>
									<OrgaSkillSection skills={skills} setSkills={setSkills} />
								</Box>
								<Stack direction={'row'} spacing={1}>
									<Box>
										<DatePicker
											disablePast
											componentsProps={{
												actionBar: {
													actions: ['clear']
												}
											}}
											value={dueDate}
											label={t('modal:massFeedback.dueDate.label')}
											onChange={(newValue: DateTime | null) => {
												setDueDate(newValue != null ? newValue.toJSDate() : null)
											}}
											renderInput={(params) => <TextField {...params} />}
										/>
									</Box>
									<Stack sx={{ justifyContent: 'center' }}>
										<Tooltip title={t('modal:massFeedback.dueDate.tooltip')}>
											<IconButton>
												<HelpOutlineIcon />
											</IconButton>
										</Tooltip>
									</Stack>
								</Stack>
							</Stack>
						</>
					)}
					<LoadingButton
						loading={isLoading}
						onClick={async () => {
							await sendFeedbackRequest()
						}}
						disabled={isSubmitDisable}
						sx={{ alignSelf: 'center' }}
						variant="contained"
					>
						{t('modal:massFeedback.send')}
					</LoadingButton>
				</Stack>
			</DialogSection>
		</Dialog>
	)
}
