import { ExportCsv } from '../../../features/modals/components/actions/ExportCsv'
import { CheckboxInput } from '../../../features/lists/components/Filters/CheckboxInput'
import { useTranslation } from 'react-i18next'
import { TextFilter } from '../../../features/lists/components/Filters/TextFilter'
import { PhoneSelect } from '../../../features/lists/components/Filters/PhoneSelect'
import { INTEGRATION_NAME, INTEGRATION_TYPE } from '@domains/INTEGRATION_TYPE'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { Divider } from '@mui/material'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'
import { type FilterInput } from '@gqlTypes'

interface Props {
	isAllRowsSelected: boolean
	refetch: (variables: { filters: FilterInput[] }) => void
	dataList: unknown[]
}

export const DiscussionsFilterBar = ({ isAllRowsSelected, refetch, dataList }: Props): ReactElement => {
	const { t } = useTranslation(['lists', 'discussions'])

	return (
		<LeftBar>
			<Filters refetch={refetch}>
				<FiltersContent>
					<TextFilter keyName="recipientRef.firstName" label={t('lists:filter.firstname')} />
					<TextFilter keyName="recipientRef.lastName" label={t('lists:filter.lastname')} />
					<TextFilter keyName="recipientRef.email" label={t('lists:filter.email')} />
					<PhoneSelect keyName="recipientRef.mobile" label={t('lists:filter.phone')} operation={undefined} />
				</FiltersContent>
				<Divider />
				<FiltersContent>
					<CheckboxInput keyName="read" translationKey="lists:filter.unread" />
					<CheckboxInput keyName="flagged" translationKey="lists:filter.flagged" />
					<CheckboxInput keyName="archived" translationKey="lists:filter.archived" />
				</FiltersContent>
			</Filters>
			<ExportCsv
				isAllRowsSelected={isAllRowsSelected}
				integrationType={INTEGRATION_TYPE.DISCUSSION}
				integrationName={INTEGRATION_NAME.DISCUSSION}
				dataList={dataList}
			/>
		</LeftBar>
	)
}
