import { useTranslation } from 'react-i18next'
import { InterviewSignature } from './InterviewSignature'
import { type GetInterviewDetailsQuery, useMeQuery, useSignInterviewMutation } from '@gqlTypes'

import { useForm } from 'react-hook-form'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { ConfirmButton } from '@ui/buttons/ConfirmButton'
import InputTextField from '@ui/form/inputTextField'
import { Typography, Tooltip, Box, Grid } from '@mui/material'
import { UndoSignatureButton } from './UndoSignatureButton'
import { ErrorHandler } from '#helpers/error.helper'
import { InterviewSection } from '../details/InterviewSection'
import { type ReactElement } from 'react'
import { useGenerateInterviewPdf } from '../details/useGenerateInterviewPdf'

interface Props {
	isVisible: boolean
	isEditable?: boolean
	interviewId: string
	signatures: GetInterviewDetailsQuery['getInterview']['signatures']
	assessorFullName: string
	assessedFullName: string
	assessorId: string
	assessedId: string
	hasPdfExport: boolean
}

export const InterviewSignatures = ({
	isVisible,
	isEditable = true,
	interviewId,
	signatures,
	assessorFullName,
	assessedFullName,
	hasPdfExport,
	assessorId,
	assessedId
}: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { data } = useMeQuery()
	const currentUserId = data?.me?.id

	const { control, getValues } = useForm()
	const [signInterviewMutation, { loading: isSaving }] = useSignInterviewMutation()
	const { generateInterviewPdf } = useGenerateInterviewPdf(interviewId)

	if (!isVisible) return null

	const isAssessor: boolean = currentUserId === assessorId
	const isAssessed: boolean = currentUserId === assessedId

	const isAssessorSigned: boolean = signatures.assessor.isSigned
	const isAssessedSigned: boolean = signatures.assessed.isSigned
	const comment = signatures.assessed.comment

	const commentTitleKey = isAssessed
		? 'interview:signatures.comment.titleAssessed'
		: 'interview:signatures.comment.title'

	const signInterview = (): void => {
		;(async () => {
			const comment: string = getValues('comment') ?? ''
			const { data } = await signInterviewMutation({ variables: { interviewId, comment } })
			const signatures = data?.signInterview?.signatures
			const isSigned = (signatures?.assessor.isSigned ?? false) && (signatures?.assessed.isSigned ?? false)
			if (!isSigned && !hasPdfExport) return

			await generateInterviewPdf()
		})().catch(ErrorHandler)
	}

	return (
		<InterviewSection title={t('interview:signatures.section')}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={4}>
					<CardLayout title={t(commentTitleKey)}>
						<InfoCardContent>
							{isAssessed && !isAssessedSigned && isEditable ? (
								<InputTextField control={control} name="comment" multiline />
							) : (
								<Typography>{comment}</Typography>
							)}
						</InfoCardContent>
					</CardLayout>
					{((isAssessor && !isAssessorSigned) || (isAssessed && !isAssessedSigned)) && isEditable ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Tooltip title={t('interview:signatures.popup.tooltip')}>
								<Box>
									<ConfirmButton
										isLoading={isSaving}
										onPressConfirm={signInterview}
										title={t('interview:signatures.popup.title')}
										message={t('interview:signatures.popup.message')}
										confirmLabel={t('interview:signatures.popup.confirmLabel')}
										cancelLabel={t('interview:signatures.popup.cancelLabel')}
									/>
								</Box>
							</Tooltip>
						</div>
					) : null}
				</Grid>
				{(isAssessorSigned || isAssessedSigned) && isEditable ? (
					<>
						<Grid item xs={12} sm={4}>
							<InterviewSignature
								isSigned={isAssessedSigned}
								fullName={assessedFullName}
								date={signatures.assessed.date ?? ''}
								type="assessed"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<InterviewSignature
								isSigned={isAssessorSigned}
								fullName={assessorFullName}
								date={signatures.assessor.date ?? ''}
								type="assessor"
							/>
						</Grid>
					</>
				) : null}
			</Grid>
			<UndoSignatureButton interviewId={interviewId} isLocked={isAssessorSigned && isAssessedSigned} />
		</InterviewSection>
	)
}
