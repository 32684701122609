import { Checkbox } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	value?: number | 'empty'
	onChange?: (value: string | number) => void
	compareValue: number
}

export const NumberCompareCheckboxFilter = ({ value, compareValue, onChange = () => null }: Props): ReactElement => {
	const isChecked = value !== 'empty'

	const onChangeValue = (_e: unknown, checked: boolean): void => {
		onChange(checked ? compareValue : 'empty')
	}
	return <Checkbox checked={isChecked} value={value} onChange={onChangeValue} />
}
