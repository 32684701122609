import { useEffect, type ReactElement } from 'react'
import { type GetUserOfferListQuery, useMeQuery } from '@gqlTypes'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Stack,
	Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserAvatar } from '@ui/UserAvatar'
import { useCandidateUpdate } from './useCandidateUpdate'
import { AssessorSelect } from '@features/lists/components/cells/AssessorSelect'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'
import { FormProvider, useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import CheckBoxField from '@ui/form/CheckBoxField'
import { IsArchivedField } from '@ui/form/IsArchivedField'

interface Props {
	offer: GetUserOfferListQuery['getUserOfferList']['nodes'][0]
	handleClose: () => void
	isOpen: boolean
}
interface FormInputs {
	status: string
	english: boolean
	assessorIds: string[]
}

export const CandidateCardEditDialog = ({ offer, handleClose, isOpen }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { update } = useCandidateUpdate(offer.id)
	const defaultValues = {
		status: offer.offerStatus?.id ?? '',
		english: offer.english ?? false,
		assessorIds: offer.assessorIds,
		isArchived: offer.isArchived ?? false
	}
	const formMethods = useForm<FormInputs>({ mode: 'onChange', defaultValues })
	const { handleSubmit, reset } = formMethods
	useEffect(() => {
		reset(defaultValues)
	}, [offer])

	const updateUserOffer = (values: FormInputs): void => {
		update(values)
		handleClose()
	}

	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	if (loading || data?.me == null) return null
	const organization = data.me.organization
	const availableStatus = organization.offerStatusObject.map((status) => ({ value: status.id, label: status.label }))
	const userRef = offer.userInfo
	if (userRef == null) return null

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<FormProvider {...formMethods}>
				<DialogCloseButton onClick={handleClose} />
				<DialogTitle color="primary" sx={{ mb: 3, fontWeight: 'bold' }}>
					{t('candidates:update.title')}
				</DialogTitle>
				<DialogContent sx={{ minWidth: 400 }}>
					<Stack direction="row" gap={2} sx={{ mb: 5 }} alignItems="center">
						<UserAvatar user={userRef} />
						<Box>
							<Typography sx={{ fontWeight: 'bold' }}>{userRef.displayName ?? ''}</Typography>
							<Typography>{offer.orgaOffer?.label}</Typography>
							<Typography style={{ fontWeight: 'bold', color: '#0A2E56' }}>{offer.offerStatus?.label ?? ''}</Typography>
						</Box>
					</Stack>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Stack spacing={3}>
								<AutocompleteSelect
									name="status"
									items={availableStatus}
									label={t('candidates:columns.status')}
									noneValueLabel={t('candidates:cell.status.none')}
								/>
								<FormControlLabel control={<CheckBoxField name="english" />} label={t('candidates:columns.english')} />
								<IsArchivedField />
							</Stack>
						</Grid>
						<Grid item xs={12} sm={6}>
							<AssessorSelect />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t('candidates:update.cancel')}</Button>
					<LoadingButton onClick={handleSubmit(updateUserOffer)} color="secondary" variant="contained">
						{t('candidates:update.edit')}
					</LoadingButton>
				</DialogActions>
			</FormProvider>
		</Dialog>
	)
}
