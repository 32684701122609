import {
	type GetUserOfferQuery,
	type PersonalityAvatar,
	type PersonalityDimension,
	type PersonalityProfile,
	useGetUserOfferQuery,
	useGetUserPositionQuery,
	type UserInterview
} from '@gqlTypes'
import { INTEGRATION_TYPE } from '@domains/INTEGRATION_TYPE'
import { type Tab } from '@shared/components/layout/menus/menus'

export interface useDetailsReturns {
	avatar?: PersonalityAvatar
	subMenuTabs: Tab[]
	dimensions?: PersonalityDimension[]
	highestDimensions?: string
	isLoading: boolean
	imageReturnResponsesCount?: number
	profile?: PersonalityProfile
	integrationInfo?: GetUserOfferQuery['getUserIntegration']
	userInfo: NonNullable<GetUserOfferQuery['getUserIntegration']>['userInfo']
	isInterviewSendingFeedback: boolean
	isInterviewAverageMandatoryOnly: boolean
	latestInterview?: UserInterview
	fieldList: {
		fieldId: string
		labelOrganization: string
		values: {
			id: string
			label: string
		}[]
	}[]
}

export const useDetails = (id: string, subMenuTabs: Tab[], type: string): useDetailsReturns => {
	const isCandidacy = type === INTEGRATION_TYPE.CANDIDACY
	const useGetUserIntegration = isCandidacy ? useGetUserOfferQuery : useGetUserPositionQuery
	const variables = { id, userIntegrationType: isCandidacy ? 'offer' : 'position' }
	const { loading, data } = useGetUserIntegration({ variables })
	const userIntegrationData = data?.getUserIntegration ?? undefined
	const userInfo = userIntegrationData?.userInfo
	const integrationInfo = userIntegrationData
	const personalityTest = userIntegrationData?.userInfo?.personalityTest?.[0]
	const dimensions = personalityTest?.dimensions ?? []
	const latestInterview = userIntegrationData?.latestInterview ?? undefined
	const avatar = personalityTest?.avatar
	const fieldList = userIntegrationData?.fieldList ?? []

	// TODO Move to interview sections for optimization
	const isInterviewSendingFeedback = userIntegrationData?.trackIntegration?.isInterviewSendingFeedback ?? false
	const isInterviewAverageMandatoryOnly =
		userIntegrationData?.trackIntegration?.isInterviewAverageMandatoryOnly ?? false
	// const isFeedbackAverageVisible = getUserOffer?.isFeedbackAverageVisible

	const imageReturnResponsesCount = userInfo?.imageReturnCount ?? undefined

	return {
		avatar,
		subMenuTabs,
		dimensions,
		highestDimensions: personalityTest?.highestDimensions,
		isLoading: loading,
		imageReturnResponsesCount,
		profile: personalityTest?.profile,
		integrationInfo,
		userInfo,
		isInterviewSendingFeedback,
		isInterviewAverageMandatoryOnly,
		latestInterview,
		fieldList
	}
}
