import { Route, Switch, useLocation, useParams } from 'react-router-dom'
import {
	ROUTE_CANDIDATES,
	ROUTE_CANDIDATE_DETAIL,
	ROUTE_CANDIDATE_DETAIL_TAB_DISCUSSION,
	ROUTE_CANDIDATE_DETAIL_TAB_INFO,
	ROUTE_CANDIDATE_DETAIL_TAB_PROGRESSION,
	ROUTE_CANDIDATE_DETAIL_TAB_ORGA_RETURN,
	ROUTE_CANDIDATE_DETAIL_TAB_PROFILE,
	ROUTE_CANDIDATE_DETAIL_TAB_RETURNS,
	ROUTE_CANDIDATE_DETAIL_TAB_CAREER
} from '../../../router/routes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { TabBar } from '@features/details/components/TabBar'
import { Discussion } from '@domains/discussions/tab/Discussion'
import { useTranslation } from 'react-i18next'
import { ImageReturnTab } from '@domains/feedbacks/tab/ImageReturnTab'
import { IntegrationType, TrackType, useUpdateUserOfferMutation } from '@gqlTypes'
import { IntegrationProgression } from '@features/details/pages/userPositions/IntegrationProgression'
import { Container } from '@mui/material'
import { Informations } from '@features/details/components/informationsTab/Informations'
import { Profile } from '@domains/profile/Profile'
import { INTEGRATION_NAME, INTEGRATION_TYPE } from '@domains/INTEGRATION_TYPE'
import { OrgaReturn } from '@domains/brandFeedbacks/tab/OrgaReturn'
import { CareerMatchings } from '@domains/careerMatching/CareerMatchings'
import { type ReactElement } from 'react'
import { getCandidacyTabs } from '@shared/components/layout/menus/menus'
import { useDetails } from '@features/details/hooks/useDetails'

export const CandidatesDetails = (): ReactElement => {
	const { userOfferId } = useParams<{ userOfferId: string }>()
	const candidateSubMenuTabs = getCandidacyTabs(userOfferId)
	const details = useDetails(userOfferId, candidateSubMenuTabs, INTEGRATION_TYPE.CANDIDACY)
	const infos = { ...details, integrationName: INTEGRATION_NAME.CANDIDATE }
	const { t } = useTranslation()
	const { state } = useLocation<{ interviewId: string }>()
	const profilePath = ROUTE_CANDIDATE_DETAIL_TAB_PROFILE
	const discussionPath = ROUTE_CANDIDATE_DETAIL_TAB_DISCUSSION
	const progression = ROUTE_CANDIDATE_DETAIL_TAB_PROGRESSION
	const informationsPath = [ROUTE_CANDIDATE_DETAIL, ROUTE_CANDIDATE_DETAIL_TAB_INFO]
	const orgaReturnPath = ROUTE_CANDIDATE_DETAIL_TAB_ORGA_RETURN
	const careerPath = ROUTE_CANDIDATE_DETAIL_TAB_CAREER
	const returnsPath = ROUTE_CANDIDATE_DETAIL_TAB_RETURNS
	const backRoute =
		state?.interviewId != null ? `/interview/offer/${userOfferId}/${state?.interviewId}` : ROUTE_CANDIDATES

	const [updateUserOffer] = useUpdateUserOfferMutation()

	const updateIntegration = async (field: string, newValue: string): Promise<void> => {
		if (infos.integrationInfo == null) return
		await updateUserOffer({
			variables: { userOfferId: infos.integrationInfo.id, userOfferInput: { [field]: newValue } }
		})
	}

	if (infos.isLoading) return <LocalLoader />

	return (
		<Container maxWidth="xl">
			<TabBar
				subMenuTabs={infos.subMenuTabs}
				fullName={infos.integrationInfo?.userInfo?.firstName + ' ' + infos.integrationInfo?.userInfo?.lastName}
				backRoute={backRoute}
				backToolTip={t('menu.candidates')}
			/>
			<Switch>
				<Route path={profilePath}>
					{infos.integrationInfo != null && infos.userInfo != null && (
						<Profile
							integrationType={INTEGRATION_TYPE.CANDIDACY}
							id={userOfferId}
							trackType={TrackType.Offer}
							discussionRoute={discussionPath}
						/>
					)}
				</Route>
				<Route path={discussionPath}>
					<Discussion infos={infos} />
				</Route>
				<Route path={progression}>
					<IntegrationProgression
						userIntegrationId={infos.integrationInfo?.id}
						userName={infos.integrationInfo?.userInfo?.displayName}
						trackType={TrackType.Offer}
					/>
				</Route>
				<Route path={returnsPath}>
					{infos.integrationInfo != null && (
						<ImageReturnTab userIntegrationId={userOfferId} userIntegrationType={IntegrationType.Offer} />
					)}
				</Route>
				<Route path={orgaReturnPath}>
					<OrgaReturn filterKey="senderRef.userId" value={infos.userInfo.userId ?? ''} />
				</Route>
				<Route path={careerPath}>
					<CareerMatchings userIntegrationId={infos.integrationInfo?.id ?? ''} trackType={TrackType.Offer} />
				</Route>
				<Route exact path={informationsPath}>
					{infos.integrationInfo != null && infos.userInfo != null && (
						<Informations infos={infos} updateIntegration={updateIntegration} />
					)}
				</Route>
			</Switch>
		</Container>
	)
}
