import { Container } from '@mui/material'
import { useState, type ReactElement } from 'react'
import { Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useParams } from 'react-router-dom'

export const InterviewPdf = (): ReactElement | null => {
	const { encodedFilePath } = useParams<{ interviewId: string; encodedFilePath: string }>()

	const [numPages, setNumPages] = useState<number[]>([])

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
		setNumPages(Array.from({ length: numPages }, (_, i) => i + 1))
	}

	const fileUrl = atob(decodeURIComponent(encodedFilePath))

	return (
		<Container maxWidth={false}>
			<Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
				{numPages.map((value) => (
					<Page key={value} pageNumber={value} width={window.innerWidth - window.innerWidth * (10 / 100)} />
				))}
			</Document>
		</Container>
	)
}
