import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	organizationType: string
}

export const OrganizationWebsite = ({ organizationType }: Props): ReactElement => {
	const { t } = useTranslation()
	const message = t('organizationCreation:requiredField')

	return (
		<InputTextFieldForm
			name="organizationWebsite"
			label={t(`organizationCreation:organization.${organizationType}.field.website`)}
			rules={{
				required: { value: true, message },
				pattern: {
					value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
					message: t('organizationCreation:error.website')
				}
			}}
		/>
	)
}
