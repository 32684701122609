import { useEffect, useState } from 'react'
import { discussionUserService } from '../../../services/Services'
import { readQuerySnapshot } from '@infrastructure/firebase/firestore.helper'
import { useErrorHandler } from 'react-error-boundary'
import { useOrganization } from '@domains/organization/useOrganization'

export default function useDiscussion(integrationInfo) {
	const { organizationId } = useOrganization()
	const errorHandler = useErrorHandler()

	const [discussionUser, setDiscussionUser] = useState(null)

	useEffect(() => {
		if (integrationInfo && organizationId) {
			discussionUserService
				.hasDiscussionUser(integrationInfo.userInfo.userId, organizationId)
				.then((discussionUserSnapshot) => {
					if (readQuerySnapshot(discussionUserSnapshot).length > 0) {
						setDiscussionUser(readQuerySnapshot(discussionUserSnapshot)[0])
					}
				})
				.catch(errorHandler)
		}
	}, [errorHandler, organizationId, integrationInfo])

	return {
		discussionUser,
		setDiscussionUser
	}
}
