import { Box, Chip, Stack } from '@mui/material'
import MDIIcon from '@ui/icon/MDIIcon'
import { type ReactElement } from 'react'
import { CareerMatchingStatusUpdator } from './CareerMatchingStatusUpdator'

interface Props {
	label: string
	icon: string
	color: string
	id: string
	careerMatchingId: string
}

export const CareerMatchingStatus = ({ label, icon, color, id, careerMatchingId }: Props): ReactElement => {
	return (
		<>
			<Stack direction="row" alignItems="center" gap={0.5}>
				<Chip
					variant="outlined"
					sx={{ color, borderWidth: 2, borderColor: color }}
					icon={
						<Box sx={{ borderRadius: 10, bgcolor: `${color}33`, p: 0.5 }}>
							<MDIIcon style={{ display: 'block' }} sx={{ color }} name={icon} size={18} />
						</Box>
					}
					label={label}
				/>
				<CareerMatchingStatusUpdator careerMatchingId={careerMatchingId} statusId={id} />
			</Stack>
		</>
	)
}
