import InputTextField from '@ui/form/inputTextField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { useMeQuery } from '@gqlTypes'
import { type GqlFormErrors } from '#helpers/form.helper'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: boolean
	prefix?: string
	gqlFormErrors: GqlFormErrors
}

export default function OnboardingField({ control, defaultValue, gqlFormErrors }: Props): JSX.Element | null {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return null
	const prefix = data.me.organization.prefix

	const name = 'onboardingCode'

	return (
		<TableLayoutRow
			title={t(`tracks:details.formFields.${name}.title`)}
			tooltip={t(`tracks:details.formFields.${name}.tooltip`)}
		>
			<InputTextField
				control={control}
				name={name}
				defaultValue={defaultValue}
				prefix={prefix}
				gqlFormError={gqlFormErrors[name]}
			/>
		</TableLayoutRow>
	)
}
