import { type ReactElement } from 'react'
import { Switch, Route } from 'react-router-dom'
import { type TabContent } from './TabContent'

interface Props {
	routes: TabContent[]
}

export const TabsRouter = ({ routes }: Props): ReactElement => {
	return (
		<Switch>
			{routes.map(({ route }) => (
				<Route key={route.path} {...route} />
			))}
		</Switch>
	)
}
