import { type ReactElement } from 'react'
import CheckBoxField from './CheckBoxField'
import { useTranslation } from 'react-i18next'
import { FormControlLabel } from '@mui/material'

export const IsArchivedField = (): ReactElement => {
	const { t } = useTranslation()

	return <FormControlLabel control={<CheckBoxField name={'isArchived'} />} label={t('lists:filter.archived.true')} />
}
