import { useGraphRadar } from '../hook/useGraphRadar'
import MDIIcon from '@ui/icon/MDIIcon'
import { type GraphRadarSize } from '../GraphRadarSize'
import { type ReactElement } from 'react'

interface Props {
	measureIndex: number
	measuresCount: number
	color: string
	icon: string
	size: GraphRadarSize
}

export const GraphIcon = ({ measureIndex, measuresCount, color, icon, size }: Props): ReactElement => {
	const { polarToCartesian, centerX, frameMargin, iconWidth, iconSize } = useGraphRadar(size)

	const radiusPosition = centerX - frameMargin / 2
	const angle = (360 / measuresCount) * (measureIndex + 0.5)
	const iconCoordinates = polarToCartesian(radiusPosition, angle)
	return (
		<foreignObject
			x={iconCoordinates.x - iconWidth / 2}
			y={iconCoordinates.y - iconWidth / 2}
			width={iconWidth}
			height={iconWidth}
		>
			<MDIIcon size={`${iconSize * 1.5}rem`} name={icon} sx={{ color }} />
		</foreignObject>
	)
}
