import { Box, Stack, type StackProps, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { type UserRef } from '@gqlTypes'
import { UserAvatar } from '@ui/UserAvatar'

interface Props extends StackProps {
	userRef?: UserRef | null
	title: string
	subtitle: string
}

export const IntegrationAvatar = ({ userRef, title, subtitle, ...StackProps }: Props): ReactElement | null => {
	if (userRef == null) return null
	return (
		<Stack direction="row" gap={2} alignItems="center" {...StackProps}>
			<UserAvatar user={userRef} />
			<Box>
				<Typography sx={{ fontWeight: 'bold' }}>{userRef.displayName ?? ''}</Typography>
				<Typography>{title}</Typography>
				<Typography style={{ fontWeight: 'bold', color: '#0A2E56' }}>{subtitle}</Typography>
			</Box>
		</Stack>
	)
}
