import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberCompareCheckboxFilter } from '../../../ui/filter/NumberCheckboxFilter'
import { Typography } from '@mui/material'

export const LowScoreFilter = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<InputFilter keyName="score" defaultValue="empty" operation={filterOperation.LOWER_THAN}>
				<NumberCompareCheckboxFilter compareValue={3} />
			</InputFilter>
			<Typography>{t('interview:list.lowScore')}</Typography>
		</div>
	)
}
