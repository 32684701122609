import { Stack } from '@mui/material'
import { ImportFileButton } from '@ui/fileUpload/ImportFileButton'
import { useStorage } from '@infrastructure/useStorage'
import { type ReactElement, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const UploadImageField = (): ReactElement => {
	const { register, setValue } = useFormContext()
	const { uploadFiles } = useStorage()
	const [logoUrl, setLogoUrl] = useState('')
	const { t } = useTranslation()
	const [isUploading, setIsUploading] = useState(false)

	const upload = async (files: FileList | null): Promise<void> => {
		if (files === null) return
		setIsUploading(true)
		const filePath = 'subscribe'
		const result = await uploadFiles(files, filePath, true)
		setLogoUrl(result.newFiles[0].fileUrl)
		setValue('logo', `${filePath}/${result.newFiles[0].fileName}`)
		setIsUploading(false)
	}

	return (
		<Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
			{logoUrl !== '' ? <img src={logoUrl} width="80" /> : null}
			{/* eslint-disable-next-line */}
      <ImportFileButton onSelectFile={upload}
				isUploading={isUploading}
				label={t('organizationCreation:organization.upload')}
				accept="image/jpeg"
			/>
			<input ref={register} name="logo" type="hidden" />
		</Stack>
	)
}
