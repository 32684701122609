import { makeStyles } from 'tss-react/mui'
import MDIIcon from '@ui/icon/MDIIcon'
import { Tooltip } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	stepCompleted: boolean
	nextStepCompleted?: boolean
	icon: string
	label: string
}

interface StyleProps {
	stepCompleted: boolean
	radius: number
	linkCompleted: boolean
}

export const StepOverview = ({ stepCompleted, nextStepCompleted, icon, label }: Props): ReactElement => {
	const radius = 22
	const linkCompleted = stepCompleted && (nextStepCompleted ?? false)
	const { classes } = useStyles({ stepCompleted, linkCompleted, radius })
	const iconColor = 'white'

	return (
		<>
			<Tooltip title={label}>
				<div className={classes.StepOverview}>
					<MDIIcon size="0.825rem" sx={{ color: iconColor }} name={icon} />
				</div>
			</Tooltip>
			{nextStepCompleted !== undefined && <div className={classes.link} />}
		</>
	)
}

const useStyles = makeStyles<StyleProps>()((theme, { stepCompleted, radius, linkCompleted }) => {
	const filledColor = theme.palette.secondary.main
	const emptyColor = theme.palette.grays.gray1
	const stepColor = stepCompleted ? filledColor : emptyColor
	const linkColor = linkCompleted ? filledColor : emptyColor
	return {
		StepOverview: {
			zIndex: 2,
			backgroundColor: stepColor,
			borderRadius: radius,
			height: radius,
			width: radius,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		link: {
			zIndex: 1,
			backgroundColor: linkColor,
			marginTop: radius / 2 - 2,
			marginLeft: -5,
			marginRight: -5,
			height: 4,
			width: 20
		}
	}
})
