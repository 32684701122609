import { AutocompleteSelect, type AutocompleteSelectProps } from '@ui/form/AutocompleteSelect'
import { t } from 'i18next'
import { type ReactElement } from 'react'

interface Props extends Partial<AutocompleteSelectProps> {
	name: string
}

const completionValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]

export const ObjectiveCompletion = ({ name, ...props }: Props): ReactElement => {
	return (
		<AutocompleteSelect
			name={name}
			items={completionValues.map((v) => ({ value: v, label: `${v}%` }))}
			label={t('interview:objectives.modal.completion')}
			orderItems={false}
			fullWidth
			{...props}
		/>
	)
}
