import InputSelectField from '@ui/form/InputSelectField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { type Control, type FieldValues, useWatch } from 'react-hook-form'
import { type OfferStatus } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props {
	control: Control<FieldValues>
	name: string
	defaultValue?: string
	items: OfferStatus[]
}

export const StatusField = ({ control, name, defaultValue, items }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const category = useWatch({ control, name: 'category' })
	const emptyItem: OfferStatus = { id: '', label: t('tracks:details.formFields.undefinedListItem'), offerCategory: '' }
	const offerStatus = [emptyItem, ...items.filter((item) => item.offerCategory === category)]

	if (offerStatus == null) return null

	return (
		<TableLayoutRow
			title={t(`tracks:details.formFields.${name}.title`)}
			tooltip={t(`tracks:details.formFields.${name}.tooltip`)}
		>
			<InputSelectField
				name={name}
				items={offerStatus}
				control={control}
				rules={{}}
				errors={{}}
				defaultValue={defaultValue}
				displayKey="label"
			/>
		</TableLayoutRow>
	)
}
