import { type ReactElement, type FormEvent } from 'react'
import { IconButton, InputBase, Paper } from '@mui/material'
import { makeVar, type ApolloQueryResult, useReactiveVar } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
	refetch: (variables: { searchQuery: string }) => Promise<ApolloQueryResult<unknown>>
	searchPlaceHolder: string
}

export const searchQuery = makeVar('')

export const Search = ({ refetch, searchPlaceHolder }: Props): ReactElement => {
	const searchQueryValue = useReactiveVar(searchQuery)
	const onFormSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault()
		await refetch({ searchQuery: searchQuery() })
	}

	const onClose = async (): Promise<void> => {
		searchQuery('')
		await refetch({ searchQuery: '' })
	}

	return (
		<form onSubmit={onFormSubmit}>
			<Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', flex: 1, borderRadius: 10 }}>
				<InputBase
					sx={{ ml: 2, flex: 1 }}
					placeholder={searchPlaceHolder}
					inputProps={{ 'aria-label': 'search...' }}
					value={searchQueryValue}
					onChange={async (e) => {
						const value = e.target.value
						searchQuery(value)

						if (value === '') {
							await onClose()
						}
					}}
				/>
				{searchQuery() !== '' ? (
					<IconButton onClick={onClose} sx={{ p: 1 }}>
						<CloseIcon />
					</IconButton>
				) : null}
				<IconButton type="submit" sx={{ p: 1 }} aria-label="search">
					<SearchIcon />
				</IconButton>
			</Paper>
		</form>
	)
}
