import { ScreenId } from '@gqlTypes'
import { InterviewList } from './list/InterviewsList.page'
import { InterviewsDashboard } from './dashboard/InterviewsDashboard.page'
import { Redirect } from 'react-router-dom'
import { InterviewPdf } from './attachment/InterviewAttachment.page'

enum Path {
	default = '/interview',
	list = '/interview/list',
	dashboard = '/interview/dashboard',
	pdf = '/pdf/:encodedFilePath'
}

const routes = [
	{
		path: Path.list,
		children: <InterviewList />
	},
	{
		path: Path.default,
		children: <Redirect to={Path.list} />
	},
	{
		path: Path.dashboard,
		children: <InterviewsDashboard />
	},
	{
		path: Path.pdf,
		children: <InterviewPdf />
	}
]

export const InterviewsRouter = {
	roleId: ScreenId.Interviews,
	paths: Path,
	routes
}
