import { type ReactElement, useState } from 'react'
import { type FirebaseAuthError, firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import { useForm } from 'react-hook-form'
import { TextField } from '@ui/core/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from '@shared/store/actions/loader.action'
import { ROUTE_REQUEST_PASSWORD } from '../../../router/routes'
import { Alert, Box, Button, Link, Stack } from '@mui/material'
import { CardWithLogo } from '@shared/components/CardWithLogo'
import { Logo } from '@ui/layout/Logo'
import { Link as routerLink } from 'react-router-dom'
import { MicrosoftLogin } from './MicrosoftLogin'

interface FormInputs {
	username: string
	password: string
}

export const LoginForm = (): ReactElement => {
	const { t } = useTranslation()
	const [authError, setAuthError] = useState('')
	const { register, getValues, errors } = useForm<FormInputs>()
	const dispatch = useDispatch()

	const onSubmitLogin = (): void => {
		const { username, password } = getValues()
		dispatch(showLoader())
		firebaseAuth()
			.signInWithEmailAndPassword(username, password)
			.catch(function (error: FirebaseAuthError) {
				console.log(`auth error : ${error.code} - ${error.message}`)
				setAuthError(t('login.authErrorOnLogin'))
			})
			.finally(() => dispatch(hideLoader()))
	}

	return (
		<Stack spacing={4}>
			<Logo width={200} sx={{ alignSelf: 'center', mt: 2 }} />
			<CardWithLogo>
				<form>
					<Stack spacing={4}>
						<Typography alignSelf="center" variant="h2">
							{t('login.greeting')}
						</Typography>
						{authError !== '' && <Alert severity="error">{authError}</Alert>}

						<TextField
							inputRef={register({ required: t('translation:login.userNameMandatory') })}
							fullWidth
							name="username"
							label={t('login.userName')}
							error={errors.username}
							inputProps={{ autoCapitalize: 'none' }}
							helperText={errors.username != null ? errors.username.message : ''}
						/>
						<TextField
							inputRef={register({ required: t('login.passwordMandatory') })}
							fullWidth
							type="password"
							name="password"
							label={t('login.password')}
							error={errors.password}
							helperText={errors.password != null ? errors.password.message : ''}
						/>
						<Stack direction="row" sx={{ gap: 7, alignItems: 'center' }}>
							<Stack>
								<Link component={routerLink} color="primary" to={ROUTE_REQUEST_PASSWORD}>
									{t('login.forgottenPwd.button')}
								</Link>
							</Stack>
							<Box>
								<Button onClick={onSubmitLogin} fullWidth variant="contained">
									{t('login.loginButton')}
								</Button>
							</Box>
						</Stack>
					</Stack>
				</form>
			</CardWithLogo>
			<MicrosoftLogin {...{ setAuthError }} />
		</Stack>
	)
}
