import { type ReactElement } from 'react'
import { DatePicker, type DatePickerProps } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { type DateTime } from 'luxon'

export const MonthPicker = <TInputDate, TDate = TInputDate>({
	onChange,
	...props
}: DatePickerProps<TInputDate, TDate>): ReactElement => {
	return (
		<DatePicker
			{...props}
			openTo="month"
			views={['month', 'year']}
			renderInput={(params) => <TextField {...params} helperText={null} />}
			onChange={(value: TDate | null) => {
				const monthYearDate = (value as DateTime).set({ day: 2, hour: 0, minute: 0, second: 0, millisecond: 0 })
				onChange(monthYearDate as TDate)
			}}
		/>
	)
}
