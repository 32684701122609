import { Box, Card, CardActions, CardContent, Stack, Typography } from '@mui/material'
import { RouterLink } from '@ui/buttons/RouterLink'
import { type ReactElement, type ReactNode, useState } from 'react'

interface Props {
	children?: ReactNode
	actions?: (isHovered: boolean) => ReactNode
	to?: string
	navigationState?: Record<string, unknown>
	linkLabel?: string
	hasLink?: boolean
	avatar: ReactNode
	title: string
}

export const AvatarCard = ({
	actions,
	children,
	to,
	linkLabel = '',
	hasLink = true,
	avatar,
	title,
	navigationState
}: Props): ReactElement | null => {
	const [isHovered, setIsHovered] = useState(false)

	return (
		<Box
			onMouseOver={() => {
				setIsHovered(true)
			}}
			onMouseLeave={() => {
				setIsHovered(false)
			}}
			sx={{ mt: 1 }}
		>
			<Box sx={{ position: 'absolute', mt: -2, ml: 2 }}>{avatar}</Box>
			<Card elevation={3} sx={{ width: 270, borderRadius: 3, display: 'inline-block', height: '100%' }}>
				<Stack justifyContent="space-between" sx={{ height: '100%' }}>
					<CardContent sx={{ paddingBottom: 0 }}>
						<Stack direction="row" justifyContent="end" alignItems="start" sx={{ minHeight: 42 }}>
							{actions == null ? null : actions(isHovered)}
						</Stack>
						<Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'grays.gray5' }}>{title}</Typography>
						{children}
					</CardContent>
					{to != null && hasLink ? (
						<CardActions sx={{ pt: 0 }}>
							<RouterLink route={to} label={linkLabel} state={navigationState} />
						</CardActions>
					) : null}
				</Stack>
			</Card>
		</Box>
	)
}
