import InputTextField from '@ui/form/inputTextField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: string
}

export default function IntroductionField({ control, defaultValue }: Props): JSX.Element {
	const { t } = useTranslation()
	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.introduction.title')}
			tooltip={t('tracks:details.formFields.introduction.tooltip')}
		>
			<InputTextField control={control} name="introduction" defaultValue={defaultValue} multiline />
		</TableLayoutRow>
	)
}
