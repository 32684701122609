import { unescapeNewLine } from '#helpers/string.helper'
import { type FeedbackResponseCommentFragment } from '@gqlTypes'
import { Grid, Stack, type SxProps, Typography } from '@mui/material'
import { UserAvatar } from '@ui/UserAvatar'
import { useDate } from '@ui/useDate'
import { type ReactElement } from 'react'

interface Props {
	response: FeedbackResponseCommentFragment
	sx?: SxProps
}

export const SkillComment = ({ response, sx = {} }: Props): ReactElement => {
	const { fullDate } = useDate()
	const { senderRef, creationDate, relationType, bottomSkillComment } = response
	const { avatarColor, avatarUrl, initials, firstName, lastName } = senderRef

	return (
		<Grid item xs={12} sm={6} sx={sx}>
			<Stack spacing={2} direction="row">
				<UserAvatar user={{ avatarColor, avatarUrl, initials }} />
				<div>
					<Typography sx={{ fontWeight: 'bold' }}>
						{firstName} {lastName}
					</Typography>
					<Typography sx={{ color: '#757575' }}>{`${relationType?.label ?? ''} • ${fullDate(
						String(creationDate)
					)}`}</Typography>
				</div>
			</Stack>
			<Typography sx={{ fontStyle: 'italic', mt: 3, whiteSpace: 'pre-line' }}>{`"${unescapeNewLine(
				String(bottomSkillComment)
			)}"`}</Typography>
		</Grid>
	)
}
