import { IconButton, Stack } from '@mui/material'
import { ImportFileButton } from '@ui/fileUpload/ImportFileButton'
import { useStorage } from '@infrastructure/useStorage'
import { type ReactElement, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { File } from '@features/home/components/FIle'
import DeleteIcon from '@mui/icons-material/Delete'

interface Props {
	name: string
	directoryPath: string
	addLabel: string
	updateLabel: string
	pdfUrl: string
}

export const UploadPdfField = ({ name, directoryPath, addLabel, updateLabel, pdfUrl }: Props): ReactElement => {
	const { setValue } = useFormContext()
	const { uploadFiles } = useStorage()
	const [isUploading, setIsUploading] = useState(false)
	const [localPdfUrl, setLocalPdfUrl] = useState<string>()
	const pdfPath = useWatch({ name }) ?? ''
	const fileUrl = localPdfUrl ?? pdfUrl ?? ''
	const label = pdfPath === '' ? addLabel : updateLabel

	const upload = async (files: FileList | null): Promise<void> => {
		if (files === null) return
		setIsUploading(true)
		const result = await uploadFiles(files, directoryPath, true)
		setValue(name, `${directoryPath}/${result.newFiles[0].fileName}`)
		setLocalPdfUrl(result.newFiles[0].fileUrl)
		setIsUploading(false)
	}

	const deleteFile = (): void => {
		setLocalPdfUrl('')
		setValue(name, '')
	}
	const fileName = (pdfPath.split('/').pop() ?? '').replace(/\d+/g, '')

	return (
		<Controller
			name={name}
			render={() => (
				<Stack spacing={1} direction="row" justifyContent="center" alignItems="center" gap={1}>
					<ImportFileButton onSelectFile={upload} isUploading={isUploading} label={label} accept="application/pdf" />
					{fileUrl !== '' ? (
						<>
							<File name={fileName} link={fileUrl} />
							<IconButton onClick={deleteFile}>
								<DeleteIcon />
							</IconButton>
						</>
					) : null}
				</Stack>
			)}
		/>
	)
}
