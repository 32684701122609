import { type ReactElement, useEffect } from 'react'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { useTranslation } from 'react-i18next'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { FormSlider } from '@ui/core/FormSlider'
import { SliderTitle } from '@ui/core/SliderTitle'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { Controller, useFormContext } from 'react-hook-form'
import { computeAverage } from '#helpers/array.helper'
import { ScoreCalculation } from './ScoreCalculation'
import { type GetInterviewDetailsQuery } from '@gqlTypes'

interface Props {
	isReadOnly: boolean
	track: GetInterviewDetailsQuery['getInterview']['track']
	mandatorySkills: Record<string, boolean>
}

interface SkillScore {
	id: string
	score: number
}

export const InterviewGlobalEvaluationCard = ({ isReadOnly, track, mandatorySkills }: Props): ReactElement => {
	const { t } = useTranslation()
	const { control, watch, setValue, register, getValues } = useFormContext()
	const watchedSkillScores: Record<string, unknown> | ArrayLike<unknown> = watch('skillScores') ?? []

	const skillScores: SkillScore[] = Object.entries(watchedSkillScores).map(([id, score]) => ({
		id,
		score: score as number
	}))
	const globalScoreCalculation = watch('scoreCalculation')

	const validateScore = (manualScore: number): boolean | string => {
		const globalCalculatedScore = getValues('globalCalculatedScore')
		const isScoreFilled =
			globalScoreCalculation === ScoreCalculation.Average ? globalCalculatedScore > 0 : manualScore > 0

		return isScoreFilled ? true : t('translation:interviewDetailPage.score.error')
	}

	let positiveValues = skillScores.filter(({ score }) => score > 0)
	if (track.isInterviewAverageMandatoryOnly === true) {
		positiveValues = positiveValues.filter(({ id }) => mandatorySkills[id])
	}
	const average = parseFloat((computeAverage(positiveValues.map(({ score }) => score)) * 4).toFixed(3))

	useEffect(() => {
		setValue('globalCalculatedScore', average)
	}, [average, setValue, globalScoreCalculation])

	const handleChange = (_event: React.MouseEvent<HTMLElement>, newGlobalScoreCalculation: string): void => {
		if (newGlobalScoreCalculation != null) setValue('scoreCalculation', newGlobalScoreCalculation)
	}

	const onChangeSlider = (): void => {
		if (globalScoreCalculation === ScoreCalculation.Average) setValue('scoreCalculation', ScoreCalculation.Manual)
	}

	return (
		<InfoCardContent color="primary" className="boxGlobal">
			<Grid container rowSpacing={6} columnSpacing={12} justifyContent="center" alignItems="center">
				<Grid item md={6} xs={12}>
					<SliderTitle
						isWhite
						label={t('translation:interviewDetailPage.globalEvaluation.title')}
						actions={
							<Controller
								name="scoreCalculation"
								control={control}
								render={(props) => (
									<ToggleButtonGroup
										className="no-print"
										{...props}
										sx={{ border: '1px solid', borderColor: 'alphaWhite.46' }}
										size="small"
										color="primary"
										exclusive
										onChange={handleChange}
									>
										<ToggleButton
											sx={{ color: 'alphaWhite.46', '&.Mui-selected': { color: 'common.white' } }}
											value={ScoreCalculation.Average}
										>
											{' '}
											{t('translation:interviewDetailPage.scoreCalculation.average')}
										</ToggleButton>
										<ToggleButton
											sx={{ color: 'alphaWhite.46', '&.Mui-selected': { color: 'common.white' } }}
											value={ScoreCalculation.Manual}
										>
											{' '}
											{t('translation:interviewDetailPage.scoreCalculation.manual')}
										</ToggleButton>
									</ToggleButtonGroup>
								)}
							/>
						}
					>
						{t('translation:interviewDetailPage.globalEvaluation.description')}
					</SliderTitle>
					<input ref={register} name="globalCalculatedScore" type="hidden" />
					<FormSlider
						name="globalManualScore"
						step={2}
						min={0}
						max={20}
						isReadOnly={isReadOnly}
						baseScore={4}
						value={globalScoreCalculation === ScoreCalculation.Manual ? undefined : average}
						onChangeValue={onChangeSlider}
						isDarkMode
						rules={{ validate: validateScore }}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<InputTextFieldForm
						rules={{ required: { value: true, message: t('translation:interviewDetailPage.comment.globalLabel') } }}
						name="comment"
						control={control}
						minRows={4}
						multiline
						variant="outlined"
						disabled={isReadOnly}
						sx={{ '.MuiInputBase-root': { backgroundColor: 'white', borderRadius: '4px' } }}
						fullWidth
					/>
				</Grid>
			</Grid>
		</InfoCardContent>
	)
}
