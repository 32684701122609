import InputSelectField from '@ui/form/InputSelectField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { type Control, type FieldErrors } from 'react-hook-form'

interface Props {
	control: Control
	errors: FieldErrors
	defaultValue?: string
	items: { id: string; name: string }[]
}

export default function CategoryField({ control, errors, defaultValue, items }: Props): JSX.Element {
	const { t } = useTranslation()
	return (
		<>
			{items != null && (
				<TableLayoutRow
					title={t('tracks:details.formFields.category.title')}
					tooltip={t('tracks:details.formFields.category.tooltip')}
				>
					<InputSelectField
						name="category"
						items={items}
						control={control}
						rules={{ required: t('form:errors.required') }}
						errors={errors}
						defaultValue={defaultValue}
						displayKey="name"
					/>
				</TableLayoutRow>
			)}
		</>
	)
}
