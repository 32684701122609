import { type ReactElement } from 'react'
import { type FirebaseAuthError, firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import { useTranslation } from 'react-i18next'
import { hideLoader } from '@shared/store/actions/loader.action'
import app from 'firebase/compat/app'
import { Button, Card } from '@mui/material'
import { useDispatch } from 'react-redux'
import { MicrosoftIcon } from '@ui/icon/MicrosoftIcon'

interface Props {
	setAuthError: (error: string) => void
}

export const MicrosoftLogin = ({ setAuthError }: Props): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const onMicrosoftLogin = (): void => {
		const provider = new app.auth.OAuthProvider('microsoft.com')
		firebaseAuth()
			.signInWithPopup(provider)
			.catch(function (error: FirebaseAuthError) {
				console.log(`auth error : ${error.code} - ${error.message}`)
				setAuthError(t('login.authErrorOnLogin'))
			})
			.finally(() => dispatch(hideLoader()))
	}

	return (
		<Card elevation={3} sx={{ borderRadius: '25px' }}>
			<Button startIcon={<MicrosoftIcon />} onClick={onMicrosoftLogin} fullWidth variant="text" size="large">
				{t('login.loginWithMicrosoft')}
			</Button>
		</Card>
	)
}
