import { type ReactElement, useState } from 'react'
import { MassReminderIntegration } from '../components/actions/MassReminderIntegration'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IntegrationCategory, TrackType } from '@gqlTypes'
import { MassReminderInterview } from '../components/actions/MassReminderInterview'
import { IconAction } from '@ui/icon/IconAction'

interface Props {
	dataList: { id: string }[]
	trackType?: TrackType
	isAllRowsSelected: boolean
	isInterview?: boolean
	integrationCategory?: IntegrationCategory
}

export const MassReminders = ({
	dataList,
	trackType = TrackType.Offer,
	isAllRowsSelected = false,
	isInterview = false,
	integrationCategory = IntegrationCategory.Company
}: Props): ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation()
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const selectedIds = selectedRows.map((index) => dataList[index].id)
	const ids = isAllRowsSelected ? [] : selectedIds

	return (
		<>
			<IconAction
				tooltip={t('modal:massReminder.tooltip')}
				disabled={selectedRows.length < 1}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<NotificationsNoneOutlinedIcon />
			</IconAction>
			{isOpen ? (
				isInterview ? (
					<MassReminderInterview isOpen={isOpen} setOpen={setIsOpen} interviewIds={ids} />
				) : (
					<MassReminderIntegration
						isOpen={isOpen}
						setOpen={setIsOpen}
						userIntegrationsIds={ids}
						trackType={trackType}
						integrationCategory={integrationCategory}
					/>
				)
			) : null}
		</>
	)
}
