import { useGetManagersQuery } from '@gqlTypes'
import { FormControlLabel, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import CheckBoxField from '@ui/form/CheckBoxField'
import { useWatch } from 'react-hook-form'

export const TutorValidator = (): ReactElement => {
	const { t } = useTranslation()
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers ?? []
	const externalAssessorId = useWatch({ name: 'externalAssessorId' })
	const externalAssessorEmail = managers.find(({ id }) => externalAssessorId === id)?.email ?? ''

	return (
		<Stack>
			<Typography display="block">{externalAssessorEmail}</Typography>
			<FormControlLabel
				control={<CheckBoxField name="isExternalAssessorValidated" />}
				label={t('students:actions.validate')}
			/>
		</Stack>
	)
}
