import { Button, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SupportIcon from '@mui/icons-material/Support'
import i18next from 'i18next'
import { type ReactElement } from 'react'

export enum Mode {
	icon,
	icon_text
}

interface Props {
	mode?: Mode
}

export const SupportButton = ({ mode = Mode.icon }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const language = i18next.language.toUpperCase()

	const supportLink =
		language === 'FR'
			? 'https://forms.clickup.com/2550882/f/2dv32-2223/9D9Q7XE1I0BET3RLO3?Langue=FR'
			: 'https://forms.clickup.com/2550882/f/2dv32-2203/3W7PYK6KT1LTII5OVU?Langue=EN'

	if (mode === Mode.icon) {
		return (
			<Tooltip title={t('onboardingHelp:supportTooltip')}>
				<IconButton target="_blank" href={supportLink}>
					<SupportIcon color="primary" />
				</IconButton>
			</Tooltip>
		)
	}

	if (mode === Mode.icon_text) {
		return (
			<Button href={supportLink} target="_blank" startIcon={<SupportIcon color="primary" />}>
				{t('login.support.general')}
			</Button>
		)
	}

	return null
}
