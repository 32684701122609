import { Box, IconButton, Stack, Tab, Tabs, type Theme, Tooltip } from '@mui/material'
import UserToolbar from './MaintToolbarAvatar'
import { type ReactElement, useEffect, useState } from 'react'
import { isAuthenticated } from '@infrastructure/firebase/firebase.helper'
import { Link, useLocation } from 'react-router-dom'
import { isPublicPath } from '#helpers/navigation.helper'
import { useMainToolbarMenu } from './useMainToolbarMenu'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useTranslation } from 'react-i18next'
import { useLocalStorageState } from '@shared/hooks/useLocalStorageState'

export const MainToolbar = (): ReactElement | null => {
	const location = useLocation()
	const isPublicRoute = isPublicPath(location.pathname)
	const { menu, selectedItemId, currentOrganizationId, organizationList } = useMainToolbarMenu()
	const [isOpen, setIsOpen] = useLocalStorageState('isMainToolbarOpen', false)
	const [value, setValue] = useState('home')
	const { t } = useTranslation()

	useEffect(() => {
		if (menu.find(({ id }) => id === selectedItemId) != null) setValue(selectedItemId)
	}, [selectedItemId, menu])

	if (!isAuthenticated() || isPublicRoute) return null

	const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
		setValue(newValue)
	}

	const transition = (theme: Theme): string =>
		theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})

	return (
		<Box className="no-print">
			<Box sx={{ width: isOpen ? 190 : 50, transition }} />
			<Box sx={{ zIndex: 3, height: '100%', position: 'fixed' }}>
				<Stack alignItems="start" justifyContent="space-between" sx={{ minHeight: '100%', height: 'auto' }}>
					<Stack>
						<Box sx={{ ml: '11px', mt: '11px', mb: '11px' }}>
							<img width={35} height={35} src="/assets/img/icon-emage-me.png" />
						</Box>
						<Box sx={{ backgroundColor: '#E7F1FF', borderRadius: '0 20px 20px 0', py: 3, flex: 1 }}>
							<Tabs
								orientation="vertical"
								value={value}
								onChange={handleChange}
								indicatorColor="secondary"
								sx={{
									alignItems: 'start',
									width: isOpen ? 190 : 50,
									overflowX: 'hidden',
									transition
								}}
								TabIndicatorProps={{
									sx: { right: 'auto', left: '3px', width: '3px', borderRadius: '0px 10px 10px 0px' }
								}}
							>
								{menu.map(({ id, path, icon, label }) => (
									<Tab
										key={id}
										component={Link}
										to={path}
										value={id}
										sx={{ justifyContent: 'start', minHeight: 'auto', padding: '12px 14px', color: '#0A2E56' }}
										icon={
											<Tooltip placement="right" title={isOpen ? '' : t(label)}>
												<Box sx={{ pr: 1, display: 'flex' }}>{icon}</Box>
											</Tooltip>
										}
										label={<div style={{ whiteSpace: 'nowrap' }}>{t(label)}</div>}
										iconPosition="start"
									/>
								))}
							</Tabs>
							<IconButton
								sx={{ ml: 1 }}
								onClick={() => {
									setIsOpen(!isOpen)
								}}
							>
								{isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
							</IconButton>
						</Box>
					</Stack>
					<UserToolbar organizationList={organizationList} currentOrganizationId={currentOrganizationId} />
				</Stack>
			</Box>
		</Box>
	)
}
