import { OrganizationType, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter, { type AutoCompleteFilterProps } from './AutoCompleteFilter'
import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'

interface Props extends AutoCompleteFilterProps {
	isBasedOnOrgaType?: boolean
}

export default function LocationFilter({ isBasedOnOrgaType = false, ...props }: Props): ReactElement | null {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return <></>
	const location = data.me.location
	const organizationType = data.me.organization.type
	const availableLocations = data.me.organization.locations.map((loc: string) => ({ value: loc, label: loc }))
	const hasRestriction = location != null && location !== ''

	if (hasRestriction) return null
	const key = organizationType === OrganizationType.School ? 'campus' : 'site'
	const normalLabel = isBasedOnOrgaType ? t(`lists:filter.${key}.label`) : props.label
	const allLabel = isBasedOnOrgaType ? t(`lists:filter.${key}.allLabel`) : props.allValueLabel

	return (
		<InputFilter keyName="location" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				{...props}
				options={availableLocations}
				label={normalLabel}
				allValueLabel={allLabel}
				width="100%"
			/>
		</InputFilter>
	)
}
