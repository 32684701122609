import { useTranslation } from 'react-i18next'
import { useGetTrackOffersLabelQuery, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter, { type AutoCompleteFilterProps } from './AutoCompleteFilter'
import { type ReactElement } from 'react'

interface Props extends AutoCompleteFilterProps {
	keyName: string
	labelAll?: string
}

export const TrackOfferFilter = ({ keyName, labelAll = '', ...props }: Props): ReactElement | null => {
	const { t } = useTranslation()

	const { data: dataMe } = useMeQuery()
	const { data, loading } = useGetTrackOffersLabelQuery()
	if (loading || dataMe?.me == null || data?.getTrackOffersLabel == null) return null
	const offersAccess = dataMe.me.offersAccess.map(({ id, label }) => {
		return { value: id, label }
	})
	const availableOffers = data.getTrackOffersLabel.map((offer) => ({ value: offer.value, label: offer.label }))
	const hasRestriction = dataMe.me.offersAccess.length === 1

	if (hasRestriction) return null

	return (
		<InputFilter
			keyName={keyName}
			operation={props.multiple === true ? filterOperation.IN_ARRAY : filterOperation.EQUAL}
		>
			<AutoCompleteFilter
				{...props}
				options={offersAccess.length === 0 ? availableOffers : offersAccess}
				defaultValue=""
				label={t('lists:filter.offer.label')}
				allValueLabel={labelAll}
			/>
		</InputFilter>
	)
}
