import { type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { OrganizationInvitation } from './organization/OrganizationInvitation'
import { OrganizationAdminInvitation } from './OrganizationAdminInvitation'
import { FormProvider, useForm } from 'react-hook-form'
import { InvitationType, OrganizationType, useCreateOrganizationInvitationMutation } from '@gqlTypes'
import Center from '@ui/layout/Center'
import { OrganizationCreationPath } from './OrganizationCreation.router'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { type OrganizationCreationForm } from './organizationCreationForm'

export const OrganizationInternshipCreation = (): ReactElement => {
	const organizationType = OrganizationType.Company
	const { t } = useTranslation()
	const history = useHistory()

	const methods = useForm<OrganizationCreationForm>()
	const [createOrganizationInvitationMutation, { loading: isSaving }] = useCreateOrganizationInvitationMutation()

	const createOrganizationInvitation = async (data: OrganizationCreationForm): Promise<void> => {
		const organization = {
			name: data.organizationName,
			website: data.organizationWebsite,
			type: organizationType,
			logo: data.logo ?? '',
			siret: data.organizationSiret
		}
		const admin = {
			firstname: data.firstname,
			lastname: data.lastname,
			email: data.email,
			phone: data.phone
		}
		const type = InvitationType.OrganizationInternshipCreation
		const variables = { createOrganizationInvitationInput: { organization, admin, emails: [], type } }
		await createOrganizationInvitationMutation({ variables })
		history.push(OrganizationCreationPath.sent)
	}
	return (
		<Container sx={{ mt: 5 }}>
			<Typography sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: 35 }}>
				<Box component="span" sx={{ color: 'secondary.main' }}>
					{t('organizationCreation:title.find')}
				</Box>{' '}
				{t('organizationCreation:title.internship')}
			</Typography>
			<Stack direction="row" divider={<ChevronRight />}>
				<Typography>{t('organizationCreation:subtitle.create')}</Typography>
				<Typography>{t('organizationCreation:subtitle.post')}</Typography>
				<Typography>{t('organizationCreation:subtitle.match')}</Typography>
			</Stack>
			{/* eslint-disable-next-line */}
      <form onSubmit={methods.handleSubmit(createOrganizationInvitation)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
						<Box sx={{ m: 5 }}>
							<img src="/assets/img/welcome.svg" width="100%" />
						</Box>
					</Grid>
					<Grid item xs={12} md={7}>
						<Stack spacing={2} mt={2} sx={{ position: 'relative' }}>
							<img
								style={{ zIndex: 3, marginBottom: -35, alignSelf: 'center' }}
								width={35}
								height={35}
								src="/assets/img/icon-emage-me.png"
							/>
							<Card elevation={3} sx={{ borderRadius: '25px' }}>
								<CardContent sx={{ mt: 2 }}>
									<FormProvider {...methods}>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<OrganizationInvitation organizationType={organizationType} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<OrganizationAdminInvitation />
											</Grid>
										</Grid>
										<Center>
											<LoadingButton type="submit" color="secondary" variant="contained" loading={isSaving}>
												{t('auth:createAccount')}
											</LoadingButton>
										</Center>
									</FormProvider>
								</CardContent>
							</Card>
						</Stack>
					</Grid>
				</Grid>
			</form>
		</Container>
	)
}
