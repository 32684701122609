import { Box, Button, Container, Grid, Stack } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'
import SupportIcon from '@mui/icons-material/Support'
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { useTranslation } from 'react-i18next'
import { Mode, SupportButton } from '@application/support/SupportButton'
interface Props {
	children: ReactNode
}

export const LoginLayout = ({ children }: Props): ReactElement => {
	const { t } = useTranslation()
	return (
		<Stack sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
			<Box></Box>
			<Container maxWidth="lg" sx={{ mb: 5 }}>
				<Grid container>
					<Grid item sm={12} md={6} sx={{ p: 10, pb: 0, display: { sm: 'none', md: 'block' } }}>
						<img src="/assets/img/welcome.svg" width="100%" />
					</Grid>
					<Grid item sm={12} md={6}>
						<Container maxWidth="xs">{children}</Container>
					</Grid>
				</Grid>
			</Container>
			<Stack spacing={2} direction="row" sx={{ mb: 1 }}>
				<Button
					href="https://emage-me.com/education/faq-etudiant"
					target="_blank"
					startIcon={<SupportIcon />}
					variant="text"
				>
					{t('login.support.school')}
				</Button>
				<Button href="https://emage-me.com/entreprise/faq-collaborateur" target="_blank" startIcon={<SupportIcon />}>
					{t('login.support.company')}
				</Button>
				<Button
					href="https://emage-me.com/termsofservice-fr.pdf"
					target="_blank"
					startIcon={<DescriptionOutlinedIcon />}
				>
					{t('login.policy.CGU')}
				</Button>
				<Button
					href="https://emage-me.com/confidentiality-policy-fr.pdf"
					target="_blank"
					startIcon={<SafetyCheckOutlinedIcon />}
				>
					{t('login.policy.confidentiality')}
				</Button>
				<SupportButton mode={Mode.icon_text} />
			</Stack>
		</Stack>
	)
}
