import { useState } from 'react'
import { CommentFilterId } from '../CommentFilterId'
import { type OrganizationFeedbackComment } from '../OrganizationFeedbackComment'
import moment from 'moment'

interface UseCommentFilters {
	filter: string
	setFilter: (filter: string) => void
	displayMore: () => void
	hasDisplayMore: boolean
	filteredComments: OrganizationFeedbackComment[]
}

export const useCommentFilters = (comments: OrganizationFeedbackComment[]): UseCommentFilters => {
	const [filter, setFilter] = useState<string>(CommentFilterId.mostRecent)
	const [itemCount, setItemCount] = useState(20)

	const itemCountDisplayed = Math.min(itemCount, Math.min(Math.ceil(comments.length / 2)))
	const filteredComments = [...comments]
		.map((comment, index) => ({ ...comment, key: index.toString() }))
		.sort((a, b) => {
			switch (filter) {
				case CommentFilterId.mostRecent:
					return moment(b.creationDate).unix() - moment(a.creationDate).unix()
				case CommentFilterId.bestScores:
					return b.score - a.score
				case CommentFilterId.lowerScores:
					return a.score - b.score
			}
			return 0
		})
		.slice(0, itemCountDisplayed)

	const hasDisplayMore = itemCount < Math.ceil(comments.length / 2)

	const displayMore = (): void => {
		setItemCount((itemCount) => itemCount + 20)
	}

	return {
		hasDisplayMore,
		displayMore,
		filter,
		setFilter,
		filteredComments
	}
}
