import { useState, type ChangeEvent, type ReactElement, useEffect } from 'react'
import { useCreateUserFromInvitationMutation, useGetInvitationQuery } from '@gqlTypes'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { Stack } from '@mui/system'
import { PasswordRules } from '@features/password/components/PasswordRules'
import { CardContent, Container, Grid, TextField, Typography } from '@mui/material'
import { PasswordInput } from '@features/password/components/PasswordInput'
import { LoadingButton } from '@mui/lab'
import { usePasswordValidation } from '@features/password/hooks/usePasswordValidation'
import { InvitationNotFound } from '../components/InvitationNotFound'
import { ErrorHandler } from '#helpers/error.helper'
import Center from '@ui/layout/Center'
import { CardWithLogo } from '@shared/components/CardWithLogo'

export const Invitation = (): ReactElement => {
	const { invitationId } = useParams<{ invitationId: string }>()
	const { t } = useTranslation()
	const history = useHistory()
	const variables = { invitationId }
	const { data, loading: isLoading, error } = useGetInvitationQuery({ variables })
	const [createUserMutation, { loading: isSaving }] = useCreateUserFromInvitationMutation()
	const [password, setPassword] = useState('')
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const { isValidPassword } = usePasswordValidation(password)

	const createUser = (): void => {
		;(async () => {
			const variables = { createUserFromInvitationInput: { invitationId, firstname, lastname, password } }
			const { data } = await createUserMutation({ variables })
			const token: string = data?.createUserFromInvitation ?? ''
			await firebaseAuth().signInWithCustomToken(token)
			history.push('/')
		})().catch(ErrorHandler)
	}

	const email = data?.getInvitation.email ?? ''
	useEffect(() => {
		setFirstname(data?.getInvitation.firstname ?? '')
		setLastname(data?.getInvitation.lastname ?? '')
	}, [data?.getInvitation])

	if (isLoading) return <LocalLoader withPadding load />
	if (error != null) return <InvitationNotFound />

	return (
		<Container maxWidth="md">
			<Stack spacing={2} alignItems="center" mt={2}>
				<Typography variant="h5" pb={3}>
					<b>{t('auth:accountCreation')}</b>
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} md={6} sx={{ mt: 2 }}>
						<Center>
							<Stack spacing={2} alignItems="left">
								<PasswordRules password={password} />
							</Stack>
						</Center>
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack spacing={2} alignItems="center">
							<CardWithLogo sx={{ width: 320 }}>
								<CardContent style={{ alignItems: 'center' }}>
									<Stack spacing={2} alignItems="center">
										<TextField variant="standard" id="email" label="email" fullWidth value={email} disabled />
										<TextField
											variant="standard"
											id="firstname"
											name="firstname"
											label={t('auth:firstNameInputLabel')}
											value={firstname}
											fullWidth
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												setFirstname(event.target.value)
											}}
										/>
										<TextField
											variant="standard"
											id="lastName"
											name="lastName"
											label={t('auth:lastNameInputLabel')}
											value={lastname}
											fullWidth
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												setLastname(event.target.value)
											}}
										/>
										<PasswordInput {...{ password, setPassword }} />
									</Stack>
								</CardContent>
							</CardWithLogo>
							<LoadingButton
								variant="contained"
								disabled={firstname === '' || lastname === '' || !isValidPassword}
								loading={isSaving}
								onClick={createUser}
							>
								{t('auth:createAccount')}
							</LoadingButton>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</Container>
	)
}
