import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { useGetManagersQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const ManagerSelect = (): ReactElement => {
	const { t } = useTranslation()
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers ?? []
	const availableManagers = managers.map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName }))
	return (
		<AutocompleteSelect
			name="manager"
			noneValueLabel={t('students:cell.referent.none')}
			items={availableManagers}
			label={t('students:columns.manager')}
		/>
	)
}
