import { type ReactElement } from 'react'
import { Stack, Tooltip, Typography } from '@mui/material'
import MDIIcon from '@ui/icon/MDIIcon'
import theme from '@ui/theme'

interface Props {
	assessorFullName: string
}

export const InterviewAssessor = ({ assessorFullName }: Props): ReactElement => {
	return (
		<Stack direction="row" gap={1} alignItems="center">
			<MDIIcon sx={{ color: theme.palette.grays.gray5 }} name="account-tie" size={18} />
			<Tooltip title={assessorFullName}>
				<Typography sx={{ fontSize: '16px', color: 'grays.gray5', textOverflow: 'ellipsis' }} noWrap>
					{assessorFullName}
				</Typography>
			</Tooltip>
		</Stack>
	)
}
