import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { functions } from '@infrastructure/firebase/firebase.helper'

interface Return {
	isGeneratingPdf: boolean
	generateInterviewPdf: () => Promise<void>
}

export const useGenerateInterviewPdf = (interviewId: string): Return => {
	const client = useApolloClient()
	const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)

	const generateInterviewPdf = async (): Promise<void> => {
		setIsGeneratingPdf(true)
		const { data } = await functions().httpsCallable('interviewPdf')(interviewId)
		setIsGeneratingPdf(false)
		client.cache.modify({
			id: `UserInterview:${interviewId}`,
			fields: {
				pdfUrl() {
					return data
				}
			}
		})
	}

	return {
		isGeneratingPdf,
		generateInterviewPdf
	}
}
