import type React from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { TextField } from '@ui/core/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useSendPasswordTokenMutation } from '@gqlTypes'
import { ErrorHandler } from '#helpers/error.helper'
import { Link } from 'react-router-dom'
import { CardWithLogo } from '@shared/components/CardWithLogo'
import { Box, Button, Stack } from '@mui/material'
import { Logo } from '@ui/layout/Logo'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LoadingButton } from '@mui/lab'

interface FormInputs {
	email: string
}

export const ForgottenPasswordForm: React.FC = () => {
	const { t } = useTranslation(['translation'])
	const [isEmailSent, setIsEmailSent] = useState(false)
	const [email, setEmail] = useState('')
	const { register, getValues, errors, trigger, setError } = useForm<FormInputs>()
	const [sendPasswordToken, { loading }] = useSendPasswordTokenMutation()

	const onSubmit = (): void => {
		;(async () => {
			const email = getValues('email')
			await trigger('email')
			if (errors.email !== undefined) return
			setEmail(email)
			await sendPasswordToken({
				variables: { email },
				onError: () => {
					setError('email', { type: 'custom', message: t('translation:login.forgottenPwd.notFound', { email }) })
				},
				onCompleted: () => {
					setIsEmailSent(true)
				}
			})
		})().catch(ErrorHandler)
	}

	let content
	if (isEmailSent) {
		content = (
			<Stack spacing={3}>
				<Typography sx={{ alignSelf: 'center' }} variant="h2">
					{t('translation:login.forgottenPwd.title')}
				</Typography>
				<Typography textAlign="center">{t('translation:login.forgottenPwd.sent', { email })}</Typography>
				<Box alignSelf="center">
					<Button fullWidth variant="contained" component={Link} to="/">
						{t('translation:login.forgottenPwd.backAfterReset')}
					</Button>
				</Box>
			</Stack>
		)
	} else {
		content = (
			<form>
				<Stack spacing={3}>
					<Typography alignSelf="center" variant="h2">
						{t('translation:login.forgottenPwd.title')}
					</Typography>
					<Typography sx={{ textAlign: 'center' }}>{t('translation:login.forgottenPwd.instructions')}</Typography>
					<TextField
						inputRef={register({
							required: t('translation:login.emailMandatory'),
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: t('translation:login.emailFormat')
							}
						})}
						fullWidth
						name="email"
						label={t('translation:login.email')}
						error={errors.email != null}
						helperText={errors.email != null ? errors.email.message : ''}
					/>
					<Stack direction="row" justifyContent="space-between">
						<Button component={Link} variant="text" startIcon={<ArrowBackIcon />} to="/">
							{t('translation:login.forgottenPwd.back')}
						</Button>
						<Box>
							<LoadingButton loading={loading} onClick={onSubmit} fullWidth variant="contained">
								{t('translation:login.reset')}
							</LoadingButton>
						</Box>
					</Stack>
				</Stack>
			</form>
		)
	}

	return (
		<Stack justifyContent="center" alignItems="center" spacing={4}>
			<Logo width={200} sx={{ mt: 2 }} />
			<CardWithLogo>{content}</CardWithLogo>
		</Stack>
	)
}
