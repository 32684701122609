import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { useMeQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const WorkArrangementsSelect = (): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	const { errors } = useFormContext()

	const items = (data?.me?.organization?.matchingRestrictions?.workArrangements ?? []).map((item) => ({
		value: item.id,
		label: item.label
	}))
	if (items.length <= 1) return null

	const name = 'workArrangementId'
	const hasError = errors[name] != null
	const errorMessage = errors[name]?.message ?? ''

	return (
		<TableLayoutRow translationKey="offers:details.main.workArrangements">
			<AutocompleteSelect
				name={name}
				items={items}
				loading={loading}
				rules={{ required: t('form:errors.required') }}
				hasError={hasError}
				errorMessage={errorMessage}
			/>
		</TableLayoutRow>
	)
}
