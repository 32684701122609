import { useMeQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { ChipSelector } from '@ui/form/ChipSelector'
import { type ReactElement } from 'react'

export const ContractDurationSelect = (): ReactElement | null => {
	const { data } = useMeQuery()
	const items = data?.me?.organization?.matchingRestrictions?.contractDurations ?? []
	if (items.length <= 1) return null

	return (
		<TableLayoutRow translationKey="offers:details.main.duration">
			<ChipSelector name="contractDurationIds" items={items} minSelectableItem={1} />
		</TableLayoutRow>
	)
}
