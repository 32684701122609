import { type GetCareerMatchingsQuery } from '@gqlTypes'
import { Card, CardContent, LinearProgress, Stack, Tooltip, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { CareerMatchingDetailScores } from './CareerMatchingDetailScores'
import MDIIcon from '@ui/icon/MDIIcon'
import { reduceString } from '#helpers/string.helper'
import { CareerMatchingStatus } from './CareerMatchingStatus'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	matching: GetCareerMatchingsQuery['getCareerMatchings'][0]
}

export const CareerMatchingCard = ({ matching }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const profile = matching.careerProfile
	const domainLabels = profile.motivationLabels.domains.map((domain) => domain.label).join(', ')
	const { status } = matching
	return (
		<Card elevation={3} sx={{ width: 270, borderRadius: 3, display: 'inline-block', height: '100%' }}>
			<Stack justifyContent="space-between" sx={{ height: '100%' }}>
				<CardContent>
					<Stack sx={{ height: 155 }}>
						<Stack direction="row" sx={{ alignItems: 'center', mb: 1, justifyContent: 'space-between' }}>
							<MDIIcon color="primary" name={profile.icon} size={60} />
							<CareerMatchingStatus {...status} careerMatchingId={matching.id} />
						</Stack>

						<Stack justifyContent="space-between" sx={{ gap: 1 }}>
							<Typography fontWeight="bold" style={{ wordBreak: 'break-word' }}>
								{profile.label}
							</Typography>
							<Tooltip title={domainLabels}>
								<Typography>{reduceString(domainLabels, 25)}</Typography>
							</Tooltip>
						</Stack>
					</Stack>
					<Typography color="secondary">{t('careerMatching:list.affinity', { score: matching.score })}</Typography>
					<LinearProgress value={matching.score} variant="determinate" sx={{ mb: 2 }} color="secondary" />
					<CareerMatchingDetailScores detailScores={matching.scoreDetails} />
				</CardContent>
			</Stack>
		</Card>
	)
}
