import { ROUTE_STUDENTS } from '../../../router/routes'
import { UserPositionsDetails } from '../../../features/details/pages/userPositions/UserPositionsDetails'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'
import { INTEGRATION_NAME } from '../../INTEGRATION_TYPE'
import { getStudentsTabs } from '@shared/components/layout/menus/menus'
import { useDetails } from '@features/details/hooks/useDetails'

export const StudentsDetails = (): ReactElement => {
	const { t } = useTranslation()
	const { userPositionId } = useParams<{ userPositionId: string }>()
	const subMenuTabs = getStudentsTabs(userPositionId)
	const details = useDetails(userPositionId, subMenuTabs, 'userPositionInfo')
	const infos = { ...details, integrationName: INTEGRATION_NAME.STUDENT }

	return (
		<UserPositionsDetails
			infos={infos}
			userPositionId={userPositionId}
			backToolTip={t('menu.students')}
			rootPath={ROUTE_STUDENTS}
		/>
	)
}
