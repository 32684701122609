import Collections from './Collections'
import { firestore } from '@infrastructure/firebase/firebase.helper'

export default class UserOfferService {
	getUserOffersOfUser(userId, orgaId) {
		return firestore()
			.collection(Collections.UserOffer.name)
			.where('userId', '==', userId)
			.where('organizationId', '==', orgaId)
			.get()
	}
}
