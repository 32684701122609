import { Box, Tooltip } from '@mui/material'
import MDIIcon from '@ui/icon/MDIIcon'
import { CircularProgressWithContent } from '@ui/progress/CircularProgressWithContent'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	score: number
	iconName: string
	scoreName: string
}

export const CareerMatchingDetailScore = ({ score, iconName, scoreName }: Props): ReactElement => {
	const { t } = useTranslation()
	return (
		<Tooltip title={t(`careerMatching:list.scoreDetails.${scoreName}`, { completion: Math.round(score) })}>
			<Box>
				<CircularProgressWithContent value={score} size={30} thickness={4.5}>
					<MDIIcon sx={{ color: 'black' }} name={iconName} size={20} />
				</CircularProgressWithContent>
			</Box>
		</Tooltip>
	)
}
