import { Stack, Typography } from '@mui/material'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const OrganizationAdminInvitation = (): ReactElement => {
	const { t } = useTranslation()
	const { control } = useFormContext()
	const message = t('organizationCreation:requiredField')

	return (
		<Stack gap={2}>
			<Typography variant="h6" pb={1} color="primary">
				<b>{t('organizationCreation:admin.title')}</b>
			</Typography>
			<InputTextFieldForm
				control={control}
				name="firstname"
				label={t('organizationCreation:admin.field.firstname')}
				rules={{ required: { value: true, message } }}
			/>
			<InputTextFieldForm
				control={control}
				name="lastname"
				label={t('organizationCreation:admin.field.lastname')}
				rules={{ required: { value: true, message } }}
			/>
			<InputTextFieldForm
				control={control}
				name="email"
				label={t('organizationCreation:admin.field.email')}
				rules={{
					required: { value: true, message },
					pattern: { value: /^\S+@\S+\.\S+$/, message: t('organizationCreation:error.email') }
				}}
			/>
			<InputTextFieldForm
				control={control}
				name="phone"
				label={t('organizationCreation:admin.field.phone')}
				rules={{
					required: { value: true, message },
					pattern: {
						value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
						message: t('organizationCreation:error.phone')
					}
				}}
			/>
		</Stack>
	)
}
