import { AutocompleteSelect } from '@ui/form/AutocompleteSelect'
import { useGetManagersQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const TutorSelect = (): ReactElement => {
	const { setValue } = useFormContext()
	const { t } = useTranslation()
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers ?? []
	const availableManagers = managers.map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName }))
	return (
		<AutocompleteSelect
			name="externalAssessorId"
			noneValueLabel={t('students:cell.tutor.none')}
			items={availableManagers}
			label={t('students:columns.tutor')}
			onValueChange={(value) => {
				setValue('isExternalAssessorValidated', true)
			}}
		/>
	)
}
