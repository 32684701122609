import { type FC, type MouseEvent } from 'react'
import { CommentFilterId } from '../CommentFilterId'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { ToggleButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
	setFilter: (filter: string) => void
	currentfilter: string
}

export const CommentsFilters: FC<Props> = ({ setFilter, currentfilter }) => {
	const handleChange = (_e: MouseEvent<HTMLElement, globalThis.MouseEvent>, value: string): void => {
		if (value === null) return
		setFilter(value)
	}

	const { t } = useTranslation()
	return (
		<ToggleButtonGroup color="primary" value={currentfilter} exclusive onChange={handleChange}>
			<ToggleButton value={CommentFilterId.mostRecent}>
				{t(`organizationFeedback:comments.filters.${CommentFilterId.mostRecent}`)}
			</ToggleButton>
			<ToggleButton value={CommentFilterId.bestScores}>
				{t(`organizationFeedback:comments.filters.${CommentFilterId.bestScores}`)}
			</ToggleButton>
			<ToggleButton value={CommentFilterId.lowerScores}>
				{t(`organizationFeedback:comments.filters.${CommentFilterId.lowerScores}`)}
			</ToggleButton>
			<ToggleButton value={CommentFilterId.all}>
				{t(`organizationFeedback:comments.filters.${CommentFilterId.all}`)}
			</ToggleButton>
		</ToggleButtonGroup>
	)
}
