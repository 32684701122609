import {
	GetUserOfferJobMatchingStatusCountDocument,
	type JobMatchingStatusId,
	useCancelJobMatchingStatusMutation
} from '@gqlTypes'
import { Stack, Box, Typography, IconButton } from '@mui/material'
import MDIIcon from '@ui/icon/MDIIcon'
import { type ReactElement } from 'react'
import UndoOutlined from '@mui/icons-material/UndoOutlined'

interface Props {
	color: string
	icon: string
	organizationLabel: string
	jobMatchingId: string
	trackOfferId: string
	statusHistoryIds?: JobMatchingStatusId[]
}

export const JobMatchingStatus = ({
	color,
	icon,
	organizationLabel,
	jobMatchingId,
	trackOfferId,
	statusHistoryIds = []
}: Props): ReactElement => {
	const colorAlpha20 = `${color}33`
	const [cancelJobStatus] = useCancelJobMatchingStatusMutation({
		variables: { jobMatchingId },
		refetchQueries: [{ query: GetUserOfferJobMatchingStatusCountDocument, variables: { trackOfferId } }]
	})
	const hasStatusHistory = statusHistoryIds?.length > 0
	const borderStyle = hasStatusHistory
		? { borderWidth: 1.8, border: `1.8px solid ${color}`, paddingLeft: 5, borderRadius: 100 }
		: {}

	return (
		<Stack direction="row" alignItems="center" gap={1} style={borderStyle}>
			<Box sx={{ backgroundColor: colorAlpha20, borderRadius: 10, padding: 1, marginRight: hasStatusHistory ? 1 : 0 }}>
				<MDIIcon style={{ display: 'block' }} sx={{ color }} name={icon} size={18} />
			</Box>
			<Typography variant="body2" color={color}>
				<b>{organizationLabel}</b>
			</Typography>
			{hasStatusHistory ? (
				<IconButton
					aria-label="close"
					onClick={() => {
						void cancelJobStatus()
					}}
				>
					<UndoOutlined sx={{ color, width: 24, height: 24 }} />
				</IconButton>
			) : null}
		</Stack>
	)
}
