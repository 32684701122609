import { type ReactHookFormRules } from '@ui/form/ReactHookFormRules'
import { Checkbox } from '@mui/material'
import { type ReactElement } from 'react'
import { type Control, Controller } from 'react-hook-form'

interface Props {
	control?: Control
	rules?: ReactHookFormRules
	name: string
	defaultValue?: unknown
	isReversed?: boolean
}

interface Render {
	onChange: (...event: unknown[]) => void
	value: boolean
}

export default function CheckBoxField({ control, rules, defaultValue, name, isReversed = false }: Props): ReactElement {
	return (
		<Controller
			control={control}
			rules={rules}
			name={name}
			defaultValue={defaultValue}
			render={({ onChange, value }: Render) => {
				return (
					<Checkbox
						checked={isReversed ? !value : value}
						onChange={(newValue) => {
							const newVal = newValue.target.checked
							onChange(isReversed ? !newVal : newVal)
						}}
					/>
				)
			}}
		/>
	)
}
