import { useTranslation } from 'react-i18next'
import { useEffect, type ReactElement } from 'react'
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { SelfEvaluationMarker } from '@shared/components/markers/SelfEvaluationMarker'
import { ImageResponseMarker } from '@shared/components/markers/ImageResponseMarker'
import { EcosystemMarker } from '@shared/components/markers/EcosystemMarker'
import { FormSlider } from '@ui/core/FormSlider'
import { SliderTitle } from '@ui/core/SliderTitle'
import { useFormContext } from 'react-hook-form'
import { type InterviewSkill } from '@features/details/hooks/useInterviewSkills'
import { InputTextFieldForm } from '@ui/form/inputTextFieldForm'
import { SkillEvaluationQuestion } from './SkillEvaluationQuestion'
import { computeAverage } from '#helpers/array.helper'
import { useOrganization } from '@domains/organization/useOrganization'

interface Props {
	skill: InterviewSkill
	isReadOnly: boolean
	onClick: (skillId: string) => void
	isAssesseePreparation: boolean
	hasDetails: boolean
}

export const InterviewSkillEvaluation = ({
	skill,
	isReadOnly,
	onClick,
	isAssesseePreparation,
	hasDetails
}: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { control, getValues, watch, setValue } = useFormContext()
	const { hasSkillsQuestionsOnInterview } = useOrganization()
	const watchedSubSkillScores: Record<string, number> = watch(`subSkillScores.${skill.id}`) ?? {}
	const positiveValues = Object.values(watchedSubSkillScores).filter((score) => score > 0)
	const average = isAssesseePreparation ? parseFloat(computeAverage(positiveValues).toFixed(3)) : 0

	const validateComment = (skillId: string, value: string): boolean | string => {
		const score = getValues(`skillScores.${skillId}`)
		return score > 0 && score < 3 && value === '' ? t('translation:interviewDetailPage.comment.globalLabel') : true
	}

	const {
		id,
		mandatory,
		feedbackAverage,
		imageResponse,
		imageResponseDetailedLabel,
		selfEvaluationScore,
		selfEvaluatedDetailedLabel,
		label,
		descriptionOrga,
		maturityLevel,
		questions
	} = skill

	useEffect(() => {
		if (average > 0) setValue(`skillScores.${id}`, average)
	}, [average])

	return (
		<Card elevation={3}>
			<CardContent sx={{ padding: 4 }}>
				<Stack gap={2}>
					<SliderTitle
						isRequired={mandatory}
						label={label}
						description={descriptionOrga}
						isDefaultOpen={isAssesseePreparation}
						hasDetails={hasDetails && hasSkillsQuestionsOnInterview}
					>
						<SkillEvaluationQuestion
							questions={questions}
							skillId={id}
							isAssesseePreparation={isAssesseePreparation}
							isReadOnly={!isAssesseePreparation}
						/>
					</SliderTitle>
					<Grid container rowGap={2}>
						<Grid item md={6} xs={12} padding={2} className="slider">
							<FormSlider
								subLabels={maturityLevel}
								name={`skillScores.${id}`}
								step={1}
								min={0}
								max={5}
								value={average === 0 ? undefined : average}
								sx={{
									paddingTop: 1
								}}
								markers={
									isAssesseePreparation ? null : (
										<>
											<Box
												onClick={() => {
													onClick(id)
												}}
												sx={{ cursor: 'pointer' }}
											>
												<EcosystemMarker value={feedbackAverage} />
											</Box>
											<ImageResponseMarker
												value={imageResponse}
												imageResponseDetailedLabel={imageResponseDetailedLabel}
											/>
											<SelfEvaluationMarker
												value={selfEvaluationScore}
												selfEvaluatedDetailedLabel={selfEvaluatedDetailedLabel}
											/>
										</>
									)
								}
								isReadOnly={isReadOnly}
								rules={{
									min: { value: mandatory ? 1 : 0, message: t('translation:interviewDetailPage.score.error') }
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<Stack spacing={1}>
								<InputTextFieldForm
									className="box1"
									rules={{ validate: (value: string) => validateComment(id, value) }}
									name={`skillComments.${id}`}
									control={control}
									minRows={2}
									multiline
									disabled={isReadOnly}
									fullWidth
								/>
								{isAssesseePreparation || skill.selfEvaluationComment === '' ? null : (
									<Typography>
										<b>{t('interview:signatures.comment.title')}: </b>
										{skill.selfEvaluationComment}
									</Typography>
								)}
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</CardContent>
		</Card>
	)
}
