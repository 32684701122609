import { useParams } from 'react-router-dom'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import useUserBContext from '@shared/hooks/useUserBContext'
import { useGetInterviewDetailsQuery, useMeQuery } from '@gqlTypes'
import { MotivationForm } from '@domains/motivations/MotivationForm'
import { PresentationLinkCard } from './PresentationLinkCard'
import { Box, Container, Stack } from '@mui/material'
import { useRole } from '@shared/hooks/useRole'
import { InterviewSignatures } from '../signature/InterviewSignatures'
import { Masonry } from '@mui/lab'
import { PersonalityProfileCard } from '@shared/components/cards/PersonalityProfileCard'
import { CommunicationKeyCard } from '@shared/components/cards/CommunicationKeyCard'
import { InterviewForm } from './interviewEvaluation/InterviewForm'
import { InterviewAttachments } from '../attachment/InterviewAttachments'
import { InterviewSection } from './InterviewSection'
import { InterviewSurvey } from './InterviewSurvey'
import { KeyInformationsCard } from './KeyInformationsCard'
import { InterviewPdf } from './InterviewPdf'
import { type ReactElement, useRef } from 'react'
import { useOrganization } from '@domains/organization/useOrganization'

export const InterviewDetailPage = (): ReactElement | null => {
	const { interviewId } = useParams<{ interviewId: string }>()
	const { t } = useTranslation()
	const userBContext = useUserBContext()
	const { isExternal } = useRole()
	const { data: userOrganizationData } = useMeQuery()
	const userOrganization = userOrganizationData?.me?.organization ?? null
	const hasAllMotivationQuestionsRequired = userOrganization?.hasAllMotivationQuestionsRequired ?? false
	const { hasPdfExport } = useOrganization()

	const { loading: isLoading, data } = useGetInterviewDetailsQuery({
		variables: { interviewId },
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first'
	})

	const name = data?.getInterview?.userIntegration?.user?.fullName
	useDocumentTitle(t('translation:candidates.interview.title', { name }))
	const tabBarContainer = useRef(null)

	if (isLoading || data === undefined) return <LocalLoader />

	const connectedUserId = data?.me?.id ?? ''
	const interview = data.getInterview
	if (interview == null) return null
	const userIntegration = interview.userIntegration
	const track = interview.track
	const organization = userIntegration.organization
	const user = userIntegration.user
	const personalityTest = user?.personalityTest ?? []
	const profilingId = user?.profilingId ?? ''
	const { avatar, profile, highestDimensions } = personalityTest[0] ?? {
		avatar: {},
		profile: undefined,
		highestDimensions: ''
	}
	const videoConferenceLink =
		interview.videoConferenceLink !== '' ? interview.videoConferenceLink : userBContext.visioLink
	const information = interview.information
	const isManager = userBContext.id !== interview.userId

	return (
		<Container maxWidth="xl" sx={{ marginBottom: 15 }}>
			<Box sx={{ position: 'sticky', top: 0, zIndex: 1, marginBottom: 2 }} ref={tabBarContainer} />
			<Stack spacing={3} mb={6}>
				<InterviewSection title={t('interview:informations.section')}>
					<Masonry columns={2} spacing={3}>
						<KeyInformationsCard
							category={track.category ?? ''}
							registerDateLabel={userIntegration.registerDateLabel ?? ''}
							publicTitle={userIntegration.user?.publicTitle ?? ''}
							information={information}
							visioLink={videoConferenceLink}
							isEnglish={userIntegration.english ?? false}
							comment={userIntegration.comment ?? ''}
						/>
						<PersonalityProfileCard avatar={avatar} profile={profile} highestDimensions={highestDimensions} />
						{(organization?.isInterviewAttachmentActive ?? false) ? (
							<InterviewAttachments {...{ interview, connectedUserId }} />
						) : null}
						<CommunicationKeyCard profilingId={profilingId} profileId={profile?.id} />
						<PresentationLinkCard link={user?.link ?? ''} />
					</Masonry>
				</InterviewSection>
				{!isExternal ? (
					<MotivationForm interview={interview} hasAllMotivationQuestionsRequired={hasAllMotivationQuestionsRequired} />
				) : null}
				<InterviewSurvey isShowMoreExpandedByDefault interview={interview} />
				<InterviewForm
					interview={interview}
					track={track}
					userIntegration={userIntegration}
					tabBarContainer={tabBarContainer}
				/>
				<InterviewSignatures
					isEditable={!interview.isArchived}
					isVisible={interview.stepsState.interviewSignature != null && interview.stepsState.interview === true}
					interviewId={interviewId}
					signatures={interview.signatures}
					assessorFullName={interview.assessorFullName}
					assessedFullName={interview.assessedFullName}
					assessedId={interview.userId ?? ''}
					assessorId={interview.juryId ?? ''}
					hasPdfExport={hasPdfExport}
				/>
				{!isExternal ? (
					<InterviewPdf
						interviewId={interviewId}
						pdfUrl={interview.pdfUrl}
						hasGenerateRights={!interview.isDraft && isManager}
						hasPdfExport={hasPdfExport}
					/>
				) : null}
			</Stack>
		</Container>
	)
}
