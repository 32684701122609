import { Container, Stack, Typography } from '@mui/material'
import { useState, type ReactElement } from 'react'
import { useGetInterviewPdfToFixQuery } from '@gqlTypes'
import { functions } from '@infrastructure/firebase/firebase.helper'
import { LoadingButton } from '@mui/lab'

export const FixInterviewPdf = (): ReactElement => {
	const [isLoading, setIsLoading] = useState(false)
	const { data } = useGetInterviewPdfToFixQuery()
	const interviewsIds = data?.getInterviewPdfToFix ?? []
	const generatePdf = async (): Promise<void> => {
		setIsLoading(true)
		await Promise.all(
			interviewsIds.map(async (interviewId) => {
				await functions().httpsCallable('interviewPdf')(interviewId)
			})
		)
		setIsLoading(false)
	}

	return (
		<Container>
			<h1>Générer les PDF manquant</h1>
			<Stack gap={2}>
				<Typography sx={{ fontWeight: 'bold' }}>{interviewsIds.length} PDF manquant</Typography>
				<Stack>
					{interviewsIds.map((id) => (
						<Typography key={id}>{id}</Typography>
					))}
				</Stack>
				{interviewsIds.length > 0 ? (
					<LoadingButton loading={isLoading} sx={{ alignSelf: 'flex-start' }} variant="contained" onClick={generatePdf}>
						Générer {interviewsIds.length} PDF
					</LoadingButton>
				) : null}
			</Stack>
		</Container>
	)
}
