import { type FilterOperation, filterOperation } from '@ui/filter/FilterForm'
import { type ReactElement } from 'react'
import { InputFilter } from '@ui/filter/InputFilter'
import { MonthPicker } from '../../../../ui/core/MonthPicker'

interface Props {
	keyName: string
	operation?: FilterOperation
	label: string
}

export const MonthDateFilter = ({
	keyName,
	operation = filterOperation.LOWER_THAN_EQUAL,
	label
}: Props): ReactElement => {
	return (
		<InputFilter keyName={keyName} operation={operation}>
			<MonthPicker label={label} />
		</InputFilter>
	)
}
