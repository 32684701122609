import useUserBContext from '@shared/hooks/useUserBContext'
import { type GetInterviewDetailsQuery } from '@gqlTypes'
import { useRole } from '@shared/hooks/useRole'

interface InterviewState {
	isReadOnly: boolean
	isEditable: boolean
	isAssessorSigned: boolean
	isAssessedSigned: boolean
	hasStartedSigning: boolean
	isMotivationEditable: boolean
	isAssesseePreparation: boolean
	isInterviewAssessee: boolean
	hasSkillEvaluation: boolean
}

export const useInterviewState = (interview: GetInterviewDetailsQuery['getInterview']): InterviewState => {
	const userB = useUserBContext()
	const { screens } = useRole()

	const isAssessorSigned = interview.signatures.assessor.isSigned
	const isAssessedSigned = interview.signatures.assessed.isSigned
	const hasStartedSigning = isAssessorSigned || isAssessedSigned
	const isReadOnly = userB.id === interview.userId || hasStartedSigning || interview.isArchived
	const isEditable = !isReadOnly
	const isInterviewAssessee = userB.id === interview.userId
	const isMotivationEditable =
		(userB.id === interview.userId || screens.interviewGrid.hasAdminAccess) &&
		!hasStartedSigning &&
		!interview.isArchived

	const isAssesseePreparation = userB.id === interview.userId && interview.isDraft
	const hasSkillEvaluation = (isInterviewAssessee && interview.stepsState.softskills != null) || !isInterviewAssessee

	return {
		isReadOnly,
		isAssessorSigned,
		isAssessedSigned,
		hasStartedSigning,
		isEditable,
		isMotivationEditable,
		isAssesseePreparation,
		isInterviewAssessee,
		hasSkillEvaluation
	}
}
