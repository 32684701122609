import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { DialogSection } from '@shared/components/modal/dialogSection'
import Center from '@ui/layout/Center'
import { LoadingButton } from '@mui/lab'
import { firebaseAuth, storage } from '@infrastructure/firebase/firebase.helper'
import { usePutUsersFromFileMutation } from '@gqlTypes'
import { UserImportResult } from './UserImportResult'
import { ErrorHandler } from '#helpers/error.helper'
import { type ReactElement } from 'react'

interface Props {
	isOpen: boolean
	close: () => void
}

interface FormInputs {
	files: File[]
}

export const UserImportModal = ({ isOpen, close }: Props): ReactElement => {
	const { register, formState, reset, getValues } = useForm<FormInputs>({ mode: 'onChange' })
	const [putUsersFromFile, { loading, data }] = usePutUsersFromFileMutation()
	const { t } = useTranslation()

	const onSubmit = (): void => {
		;(async () => {
			const { files } = getValues()
			const file = files[0]
			if (file === undefined) return

			const userId = firebaseAuth().currentUser?.uid ?? ''
			const directory = `users/${userId}/`
			const CSVUrl = `${directory}${file.name}`
			await storage().ref().child(CSVUrl).put(file)
			const result = await putUsersFromFile({ variables: { CSVUrl } })
			const reportUrl = result.data?.putUsersFromFile.reportUrl ?? ''

			if (reportUrl !== '') {
				const downloadUrl: string = await storage().ref(reportUrl).getDownloadURL()
				window.open(downloadUrl, '_blank')
			}
			reset()
		})().catch(ErrorHandler)
	}

	return (
		<Dialog open={isOpen} onClose={close}>
			<DialogTitle>
				<>{t('modal:userImport.title')}</>
			</DialogTitle>
			<Divider />
			<UserImportResult report={data?.putUsersFromFile} />
			<form>
				<DialogSection>
					<Center>
						<input ref={register} name="files" type="file" />
					</Center>
				</DialogSection>
				<Center>
					<LoadingButton onClick={onSubmit} loading={loading} variant="contained" disabled={!formState.isValid}>
						{t('modal:userImport.import')}
					</LoadingButton>
				</Center>
			</form>
		</Dialog>
	)
}
