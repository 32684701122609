import { type ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { firebaseAuth } from '@infrastructure/firebase/firebase.helper'
import { silentErrorHandler } from '#helpers/error.helper'

export const TokenAuth = (): ReactElement | null => {
	const { token } = useParams<{ token: string }>()
	const [success, setSuccess] = useState(false)

	useEffect(() => {
		firebaseAuth()
			.signInWithCustomToken(window.atob(token))
			.catch((error) => {
				silentErrorHandler(error)
			})
			.finally(() => {
				setSuccess(true)
			})
	}, [token])

	if (success) return <h3>success</h3>
	return null
}
