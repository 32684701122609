import { useState, type ReactElement } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useTranslation } from 'react-i18next'
import { UserImportModal } from './UserImportModal'
import { IconAction } from '@ui/icon/IconAction'

export const UserImport = (): ReactElement => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<IconAction
				tooltip={t('modal:userImport.title')}
				onClick={() => {
					setIsOpen(true)
				}}
				disabled={false}
			>
				<UploadFileIcon />
			</IconAction>
			<UserImportModal
				isOpen={isOpen}
				close={() => {
					setIsOpen(false)
				}}
			/>
		</>
	)
}
