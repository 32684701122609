import { useTranslation } from 'react-i18next'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import CheckBoxField from '@ui/form/CheckBoxField'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: boolean
}

export default function ActiveField({ control, defaultValue }: Props): JSX.Element {
	const { t } = useTranslation()
	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.isActive.title')}
			tooltip={t('tracks:details.formFields.isActive.tooltip')}
		>
			<CheckBoxField control={control} name="isActive" defaultValue={defaultValue} />
		</TableLayoutRow>
	)
}
