import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { LeftBar } from '@features/lists/components/LeftBar'
import AddIcon from '@mui/icons-material/Add'
import { OffersPath } from '../OffersRouter'
import { Filters } from '@features/lists/components/Filters'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import { OfferOrganizationFilter } from './filters/OfferOrganizationFilter'
import { UserFunctionFilter } from './filters/UserFunctionFilter'
import { OfferLocationFilter } from './filters/OfferLocationFilter'
import { EducationLevelFilter } from './filters/EducationLevelFilter'
import { StartMonthFilter } from './filters/StartMonthFilter'
import { IconAction } from '@ui/icon/IconAction'

interface Props {
	refetch: () => void
}

export const OfferListToolbar = ({ refetch }: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<LeftBar>
			<IconAction tooltip={t('offers:create')} to={OffersPath.create}>
				<AddIcon />
			</IconAction>
			<Filters refetch={refetch}>
				<FiltersContent>
					<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
					<OfferOrganizationFilter />
					<UserFunctionFilter />
					<OfferLocationFilter />
					<EducationLevelFilter />
					<StartMonthFilter />
				</FiltersContent>
			</Filters>
		</LeftBar>
	)
}
